<script setup>
import { defineProps, defineEmits, computed, onMounted, useAttrs } from "vue";
import UiInputWrapper from "@/components/basic/form/ui/UiInputWrapper.vue";
import { useAsyncValidation } from "@/composition/validation/use-async-validation";
import HelpButton from "@/components/basic/HelpButton.vue";

// type Validator = (query: string) => Promise<string>

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  // Validator
  validator: {
    type: Function,
    required: true,
  },
  label: {
    type: String,
    default: "Default label",
  },
  hints: {
    type: String,
    default: "",
  },
  helpId: {
    type: String,
    default: "",
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  tag: {
    type: String,
    default: "input",
  }
});

const emit = defineEmits(["update:modelValue", "update:is-valid"]);
const emitIsValid = (errMsg) => {
  emit("update:is-valid", !errMsg);
};

const attrs = useAttrs();

const {
  model: validatedModel,
  isValid,
  error,
  subscribeToValidation,
  doValidation,
  isLoading,
} = useAsyncValidation(props.modelValue, props.validator);
subscribeToValidation(emitIsValid);


const inputValue = computed({
  get() {
    return props.modelValue;
  },
  set(v) {
    validatedModel.value = v;
    emit("update:modelValue", v);
  }
});

const isFieldValid = computed(() => {
  return isValid.value && (props.isRequired ? Boolean(inputValue.value) : true);
});

onMounted(() => {
  if (props.modelValue.trim().length > 0) {
    doValidation(props.modelValue)
  }
});
</script>

<template>
  <div
    class="text-field-async-valid"
    :data-help="helpId"
  >
    <UiInputWrapper
      class="text-field-async-valid__input-wrapper"
      :label="label"
      :hints="hints"
      :is-valid="isFieldValid"
      :errors="error"
      :is-loading="isLoading"
    >
      <input
        v-if="tag === 'input'"
        v-model="inputValue"
        class="text-field-async-valid__input"
        type="text"
        placeholder=" "
      />

      <textarea
        v-else
        v-model="inputValue"
        class="text-field-async-valid__input"
        type="text"
        placeholder=" "
        :rows="attrs.rows || 1"
      />
    </UiInputWrapper>

    <HelpButton
      class="text-field-async-valid__help-btn"
      :data-help="helpId"
    />
  </div>
</template>

<style lang="scss">
.text-field-async-valid {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: .5rem;

  &__input-wrapper {
    flex: 1;
  }

  &__input {
    resize: vertical;
  }

  &__help-btn {
    padding-top: 10px;
  }
}

</style>
