<script>
export default {
  metaInfo: {
    title: "Register",
  },
};
</script>

<script setup>
import PhpServiceView from '@/components/basic/php-wrappers/PhpServiceView.vue';
import Registration from '@/components/dynamic/registration.vue';
import {getURLSearchParam} from '@/js/general';

const code = getURLSearchParam('code');
const email = getURLSearchParam('email');

let url = '?f=global&f2=register';
if (code) {
  url += `&code=${code}`;
}
if (email) {
  url += `&email=${email}`;
}
</script>

<template>
  <PhpServiceView :url="url" public #default="{result}">
    <Registration :result="result"/>
  </PhpServiceView>
</template>


