import { useStore } from "@/store";

export const useNotifications = () => {
  const store = useStore();

  const showNotification = ({ message, length = 2000 }) => {
    store.commit("notification", { text: message, length: length });
  };

  return {
    showNotification,
  };
};
