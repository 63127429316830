<template>
  <div v-if="allowedSystem.checkList != 'undefined'">
    <input type="hidden" :value="JSON.stringify(localValue)" :name="field" />
    <v-row class="mt-n4">
      <v-col class="ml-5 text-subtitle-1">{{
        language.componentLabel.allowedFunctions
      }}</v-col>
    </v-row>
    <div class="row mt-n4 mr-4 mb-3">
      <div
        class="col ml-7"
        style="max-width: 200px"
        v-for="(cColumns, cValue) in allowedChunk"
        :key="cValue"
      >
        <template v-for="checkName in cColumns">
          <checkbox
            style="height: 30px"
            :key="Object.values(checkName).toString()"
            :fieldAttrInput="{
              ...fieldAttrInput,
              id:
                getFieldId(fieldAttrInput, result, field) +
                '_' +
                Object.values(checkName).toString(),
            }"
            :fieldAttributes="{
              name: languagePack('menu_' + Object.keys(checkName).toString()),
            }"
            @input="
              updateValue(Object.values(checkName).toString(), ...arguments)
            "
            :value="isSelected(Object.values(checkName).toString())"
            :result="result"
            :disabled="getdisabled(Object.keys(checkName).toString())"
          ></checkbox>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getFieldId, getfieldAttrInput } from "@/js/helper.js";
import checkbox from "@/commonComponents/checkbox.vue";
import { chunk } from "lodash-es";

export default {
  components: {
    checkbox,
  },
  data: function () {
    return {
      allowedSystem: this.result.fields.c_allowedTypes,
      fieldAttrInput: getfieldAttrInput(this.fieldAttributes),
    };
  },
  created() {
    this.checkValue(this.localValue);
  },
  watch: {
    value(value) {
      this.checkValue(value);
    },
    "result.valueLine": function () {
      this.$nextTick(function () {
        this.$emit("changeIsValid", true);
      });
    },
  },
  computed: {
    allowedChunk: function () {
      const chunkSize = 4;
      const arrayFromObject = Object.entries(
        this.fieldAttributes.checkList
      ).map(([key, value]) => ({ [key]: value }));
      return chunk(arrayFromObject, chunkSize);
    },
    localValue: {
      get() {
        let value = [];

        if (typeof this.value != "undefined" && this.value != "") {
          value = this.value;
        } else {
          let allowed = [];
          Object.keys(this.fieldAttributes.allowedTestingType).forEach((el) => {
            if (this.fieldAttributes.allowedTestingType[el]) {
              allowed.push(el);
            }
          });
          if (allowed.length == 1) {
            value = allowed;
          }
        }

        // console.log(value);
        return value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  methods: {
    getdisabled(checkName) {
      let allowed = false;
      if (
        typeof this.fieldAttributes.allowedTestingType[checkName] != "undefined"
      ) {
        allowed = this.fieldAttributes.allowedTestingType[checkName];
      }
      //console.log(disabled,this.fieldAttributes.allowedTestingType,checkName);
      return !allowed;
    },
    checkValue(value) {
      //console.log(value);
      if (value.length > 0) {
        this.$set(this.fieldAttrInput, "class", "");
      } else {
       // this.$set(this.fieldAttrInput, "class", "required");
        this.$set(this.fieldAttrInput, "class", "");
      }
    },
    getFieldId: getFieldId,
    updateValue(checkValue, value) {
      let localValue = [...this.localValue];
      if (value == 1) {
        localValue.push(checkValue);
      } else {
        localValue = this.localValue.filter(function (item) {
          return item != checkValue;
        });
      }
      this.$emit("input", localValue);
    },
    isSelected(value) {
      if (this.localValue.includes(value)) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  props: ["field", "fieldAttributes", "result", "value"],
};
</script>
<style>
</style>
