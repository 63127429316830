import { ref, computed } from "vue";
import { cockpitSpreadsheetService } from "@/api";
import debounce from "debounce";

// =TEMPORARY=
function addFielldsThatAreMissing(variable) {
  if (!variable.type) {
    variable.type = "text"
  }
  return variable;
}

const ITEMS_PER_PAGE = 25;

export function useVariables() {
  const isLastPage = ref(false)
  const variables = ref([])
  const currentPage = ref(0)

  const isNewVariable = (_v) => !_v.id;
  const updateVariableInList = (variable) => {
    const index = variables.value.findIndex(v => v.id === variable.id);
    if (index > -1) {
      variables.value.splice(index, 1, variable);
    }
  }
  const addNewVariableToList = (variable) => {
    variables.value.splice(0, 1, variable)
  }

  const addBlankNewVariable = () => {
    const [first] = variables.value
    if (first && !first.id) return

    variables.value.unshift({
      name: '',
      value: '',
    })
  };

  const createVariable = async (variable, { updateList = true } = {}) => {
    const _v = addFielldsThatAreMissing(variable);
    const id = await cockpitSpreadsheetService.v1.createVariable(_v);

    _v.id = id;

    if (updateList) {
      addNewVariableToList(_v)
    }
    return _v;
  }

  const fetchVariables = async () => {
    const params = {
      page: currentPage.value + 1,
      "items-per-page": ITEMS_PER_PAGE,
    };
    const variablesList = await cockpitSpreadsheetService.v1.getVariablesList(params);
    if (variablesList.length) {
      currentPage.value += 1;
      variables.value = [...variables.value, ...variablesList];
    } else {
      isLastPage.value = true;
    }
  }

  const updateOldVariable = async (variable, { updateList = true } = {}) => {
    const _v = addFielldsThatAreMissing(variable);

    await cockpitSpreadsheetService.v1.updateVariable(_v);

    if (updateList) {
      updateVariableInList(_v)
    }
  }

  const removeVariableFromList = (variable) => {
    if (isNewVariable(variable)) {
      variables.value = variables.value.filter(v => Boolean(v?.id));
      return;
    }

    variables.value = variables.value.filter(v => v.id !== variable.id);
  };

  const deleteVariable = async (variable, { updateList = true } = {}) => {
    if (isNewVariable(variable)) {
      removeVariableFromList(variable)
      return Promise.resolve();
    }
    await cockpitSpreadsheetService.v1.deleteVariable(variable.id);
    if (updateList) {
      removeVariableFromList(variable);
    }
  }

  const doVariableSearch = debounce(async (searchTerm) => {
    const params = {
      search: searchTerm,
    };
    const variablesList = await cockpitSpreadsheetService.v1.getVariablesList(params);
    variables.value = variablesList;
  }, 500)

  const $searchText = ref('')
  const searchText = computed({
    get() {
      return $searchText.value
    },
    set(value) {
      $searchText.value = value
      doVariableSearch(value)
    }
  })

  return {
    variables,
    currentPage,
    isLastPage,
    isNewVariable,
    fetchVariables,
    updateOldVariable,
    deleteVariable,
    createVariable,
    addNewVariableToList,
    doVariableSearch,
    searchText,
    removeVariableFromList,
    updateVariableInList,
    addBlankNewVariable
  }
}
