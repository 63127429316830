import Vue from "vue";
import { createPersistentStatePlugin } from "./plugins/persistent-state";
import Vuex from "vuex";
import featureStoreService from "@/js/services/FeatureStoreService";
import { userService } from "@/api";
import sims from "./modules/sims";
import help from "./modules/help";
import userModule from "./modules/user";
import fiberNet from "./modules/fiber-net";
import requests from "@/js/requests";
import { testInfoAdminInfoChannel } from "./channels/test-info-admin-info"

let snackbarId = 0

const _initialState = {
  pagePost: {},
  dialogRef: "",
  fieldDataMutated: false,
  tablesData: {},
  additionalStepsInfo: {},
  additionalSubmitData: {},
  dialogDataObject: {},
  submittedResult: {},
  submittedData: {},
  checkStartParameters: {},
  subscriberCategories: [],
  pageLoading: false,
  openedDialogIndex: null,
  showRecoveryList: false,
  notifications: [],
  tableDialog: null,
  logging: false,
  log: null,
  role: 0,
  user: {
    id: 0,
    hash: "",
    firstName: "",
    lastName: "",
    email: "",
    isNewUser: false,
  },
  permissions: {
    projectRole: 0,
    orgaRole: 0,
    superAdmin: false,
  },
  project: {
    id: 0,
    hash: "",
    name: "",
    isDemoProject: false,
    systemIds: [],
  },
  organization: {
    id: 0,
    hash: "",
    name: "",
    config: {},
  },
  systemList: [],
  menu: null,
  recentlyUsedMenuItems: {
    outboundConnections: {},
    inboundConnections: {}
  },
  testStatusSearch: "",
  refreshingJwt: false,
  emittingDefaultValue: false,
  scheduledTestsHistoryResolution: null,
  testInfoAdminInfo: false,
  currentPhpServiceViewUrl: "",
  result: null,
}

const getTableDialogConfigDefault = () => {
  return {
    active: false,
    title: "",
    text: "",
    width: 350,
    yes: () => {
      return undefined
    },
  }
}

const resetState = () => {
  // eslint-disable-next-line no-undef
  const _s = structuredClone(_initialState)

  _s.tableDialog = getTableDialogConfigDefault()

  return _s
}

const modules = {
  sims,
  help,
  userModule,
  fiberNet,
}

const plugins = [
  createPersistentStatePlugin({
    reducer: new Map([
      ["user", (state) => (state.user?.id ? state.user : null)],
      ["permissions", (state) => (state.permissions?.projectRole ? state.permissions : null)],
      ["project", (state) => (state.project?.id ? state.project : null)],
      ["organization", (state) => (state.organization?.id ? state.organization : null)],
      ["logging", (state) => state.logging],
      ["log", (state) => state.log],
      ["scheduledTestsHistoryResolution", (state) => state.scheduledTestsHistoryResolution],
      ["menu", (state) => state.menu],
      ["testInfoAdminInfo", (state) => state.testInfoAdminInfo],
      ["fiberNet.isEnabled", (state) => state["fiberNet"].isEnabled],
    ]),
    resetMutationName: "resetState",
  }),
]

let vuexCfg = {
  plugins,

  modules,

  state: resetState(),

  getters: {
    projectId: (state) => state.project.id,
    organizationId: (state) => state.organization.id,
    organizationName: (state) => state.organization.name,
    user: (state) => state.user,
    isPhpSessionLive: (state) => state?.pagePost?.data?.userInfo?.loggedIn || false,
    isSuperAdmin: (state) => state.permissions.superAdmin,
    isOrgaAdmin: (state) => state.permissions.orgaRole,
    orgaRole: (state) => state.permissions.orgaRole,
    result: (state) => state.result
  },

  mutations: {
    showRecoveryList(state, post) {
      state.showRecoveryList = post
    },
    openInfoDialog(state, post) {
      state.openedDialogIndex = post
    },
    pagePostChanged(state, post) {
      state.pagePost = post
    },
    subscriberCategoriesChanged(state, post) {
      state.subscriberCategories = post
    },
    pageLoading(state, post) {
      state.pageLoading = post
    },
    checkStartParameters(state, post) {
      state.checkStartParameters = post
    },
    tableChanged(state, table) {
      Vue.set(state.tablesData, table.id, table)
    },
    dialogResultChanged(state, result) {
      if (typeof state.dialogDataObject[result.dialogRef] == "undefined") {
        Vue.set(state.dialogDataObject, result.dialogRef, {})
      }
      Vue.set(state.dialogDataObject[result.dialogRef], "result", result)
    },
    submittedResultChanged(state, result) {
      if (typeof state.submittedResult[result.dialogRef] == "undefined") {
        Vue.set(state.submittedResult, result.dialogRef, {})
      }
      Vue.set(state.submittedResult[result.dialogRef], "result", result)
    },
    dataSubmitted(state, response) {
      Vue.set(state.submittedData, response.data.result.dialogRef, response)
      Vue.set(state.submittedData, "latestRef", response.data.result.dialogRef)
    },
    additionalSubmitDataChanged(state, additionalSubmitData) {
      for (const [key, value] of Object.entries(additionalSubmitData)) {
        if (value !== undefined) {
          state.additionalSubmitData[key] = value
        } else {
          delete state.additionalSubmitData[key]
        }
      }
    },
    resultChanged(state, result) {
      state.result = result
    },
    closeDialog(state, dialogRef) {
      let url = new URL(window.location.href)
      let params = url.searchParams
      if (url.toString().includes("/testing/configure-and-run-tests")) {
        params.delete("testCaseId")
        history.replaceState({}, "", url)
      }
      state.dialogRef = dialogRef
    },
    openResultDialog(state, post) {
      state.dialogResult = post
    },
    dialogButtonsLoading(state, post) {
      state.dialogButtonLoading = post
    },
    setFieldDataMutated(state, post) {
      state.fieldDataMutated = post
    },
    notification(state, payload) {
      let text = payload
      let length = 2000
      if (typeof payload === "object") {
        text = payload.text
        if (payload.length) length = payload.length
      }
      const notification = {
        id: snackbarId,
        text: text,
        show: true,
      }
      snackbarId++
      state.notifications.push(notification)
      setTimeout(() => {
        notification.show = false
        state.notifications.splice(state.notifications.indexOf(notification), 1)
      }, length)
    },

    updateTableDialog(state, payload) {
      state.tableDialog = payload
    },

    toggleLogging(state) {
      state.logging = !state.logging
    },

    setUserData(state, { id, hash, firstName, lastName, email, isNewUser }) {
      Object.assign(state.user, { id, hash, firstName, lastName, email, isNewUser })
    },

    setPermissions(state, { permissions }) {
      state.permissions = permissions
    },

    setProject(state, { project }) {
      state.project = project;
      const demoProjectId = parseInt(process.env.VUE_APP_DEMO_PROJECT_ID);
      project.isDemoProject = project.id === demoProjectId;
    },

    setOrganization(state, { organization }) {
      state.organization = organization
    },

    setMenu(state, payload) {
      state.menu = payload
    },

    log(state, payload) {
      if (!Array.isArray(state.log)) state.log = []
      state.log.push({
        time: new Date().getTime(),
        message: payload,
      })
      if (state.log.length > 1000) {
        state.log.splice(0, state.log.length - 1000 + 100)
      }
    },

    setEmittingDefaultValue(state, payload) {
      state.emittingDefaultValue = payload
    },

    setScheduledTestsHistoryResolution(state, payload) {
      state.scheduledTestsHistoryResolution = payload
    },

    toggleTestInfoAdminInfo(state) {
      state.testInfoAdminInfo = !state.testInfoAdminInfo
    },

    setCurrentPhpServiceViewUrl(state, payload) {
      state.currentPhpServiceViewUrl = payload
    },

    setRecentrlyUsedMenuItems(state, { outboundConnections, inboundConnections }) {
      state.recentlyUsedMenuItems.outboundConnections = outboundConnections;
      state.recentlyUsedMenuItems.inboundConnections = inboundConnections;
    },

    resetState(state) {
      const store = useStore()
      for (const moduleName of Object.keys(modules)) {
        try {
          store.commit(`${moduleName}/resetState`)
        } catch (e) {
          console.error(e)
          continue
        }
      }
      Object.assign(state, resetState())
    },
  },

  actions: {
    async triggerTestInfoUpdate({ commit, state }, { n_id, n_id_key }) {
      try {
        const resp = await requests.frameworkAxiosRequest({
          method: "post",
          url: "serve.php?f=testing&f2=testInfo&function=getInfoUpdate",
          data: {
            n_id,
            n_id_key,
            requestType: 'ajax',
            excludeRows: [],
            showInfo: state.testInfoAdminInfo,
          },
        });

        commit("tableChanged", resp?.data?.result?.table);
      } catch (err) {
        commit("notification", { text: "Error while fetching test info" });
        throw err;
      }
    },

    async toggleTestInfoAdminInfo({ commit, dispatch }, { n_id = "", n_id_key = "", isClick = false } = {}) {
      commit("toggleTestInfoAdminInfo");

      if (n_id && n_id_key) {
        await dispatch("triggerTestInfoUpdate", { n_id, n_id_key });
      }

      if (isClick) {
        testInfoAdminInfoChannel.notify();
      }
    },

    async fetchUserData({ commit, state }, { isRefresh = false } = {}) {
      if (state.user.id && !isRefresh) {
        return
      }

      const data = await userService.v2.fetchUserData();

      if (data) {
        commit("setUserData", data)
        commit("setPermissions", data)
        commit("setProject", data)
        commit("setOrganization", data)
      }
    },

    async getMenu({ commit, state }, { isRefresh = false } = {}) {
      if (state.menu && !isRefresh) {
        return
      }

      const menu = await featureStoreService.getMenu()
      if (menu) {
        commit("setMenu", menu)
      }
    },

    async fetchRecentlyUsedMenuItems({ commit }) {
      const menuItems = await userService.v1.fetchRecentlyUsedMenuItems();
      commit("setRecentrlyUsedMenuItems", menuItems);
    },

    async updateRecentlyUsedMenuItems({ commit }, menuItem) {
      const updatedDictRecentlyUsed = await userService.v1.updateRecentlyUsedMenuItems(menuItem);
      commit("setRecentrlyUsedMenuItems", updatedDictRecentlyUsed);
    },

    fetchEssentialData({ dispatch }, ...args) {
      return Promise.all([
        dispatch("fetchUserData", ...args),
        dispatch("getMenu", ...args),
        dispatch("fetchRecentlyUsedMenuItems"),
        dispatch("help/fetchHelpIdsList", ...args),
        dispatch("userModule/fetchOrgsWithProjects", ...args)
      ])
    },
  },
}

Vue.use(Vuex)
const store = new Vuex.Store(vuexCfg)

export const useStore = () => {
  return store
}



