<template>
  <section class="spreadsheets-view">
    <div class="spreadsheets-view__table-slot">
      <VariablesTable
        :headers="headers"
        :variables="variables"
      >
        <template #headline>
          <PageHeadline
            sticky
            data-help="Project_spreadsheet_variable_inputs"
          >
            <template #right>
              <XBtn
                color="primary"
                icon="mdi-plus"
                text="Add"
                min-height="40"
                min-width="130"
                @click="() => addBlankNewVariable()"
              >
                new
              </XBtn>
            </template>

            Project Spreadsheet

            <template #mid-after-headline>
              <HelpButton help="Project_spreadsheet_variable_inputs" />
            </template>

            <template #left>
              <XTextField
                label="Search by a Name"
                v-model="searchText"
                :width="364"
              />
            </template>
          </PageHeadline>
        </template>

        <template #tbody="{items}">
          <VariableRow
            v-for="variable of items"
            :key="variable.id"
            :variable="variable"
            :new-variable-id="newVariableId"
            @hook:mounted="() => onVariableRowMount()"
          >
            <template #rowStart>
              <td>
                <button
                  style="cursor: auto"
                  class="spreadsheets-view__drag-n-drop  handle"
                  title="Comming soon"
                  type="button">
                  <v-icon>
                    mdi-drag-vertical
                  </v-icon>
                </button>
              </td>
            </template>
          </VariableRow>
        </template>

        <template #lastRow>
          <VIntersectionObserver
            v-if="!isLastPage && variables.length > 0 && !searchText"
            class="intersection-observer"
            @visible="() => fetchVariables()"
          />

          <template v-else-if="variables.length === 0">
            <td :colspan="headers.length">
              <div class="text-center">
                <p class="mb-0">No variables found</p>
              </div>
            </td>
          </template>
        </template>
      </VariablesTable>
    </div>
  </section>
</template>

<script>
import { defineComponent, onBeforeMount, ref, provide } from "vue";
import { useVariables } from "@/composition/variables/use-variables";
import XBtn from "@/components/basic/XBtn.vue";
import XTextField from "@/components/basic/XTextField.vue";
import VariablesTable from "@/components/specific/VariablesTable/index.vue";
import PageHeadline from "@/components/basic/headlines/PageHeadline.vue";
import VIntersectionObserver from "@/components/basic/web-api/VIntersectionObserver.vue"
import VariableRow from "@/components/specific/VariablesTable/VariableRow.vue"
import HelpButton from "@/components/basic/HelpButton.vue"
import { useLeavePageWarning } from "@/composition/leave-page-warning";

const headers = [
  {
    text: '',
    value: 'handle',
    sortable: false,
    width: '50px',
  },
  {
    text: 'Name',
    value: 'name',
    align: 'start',
    sortable: false,
  },
  {
    text: 'Value',
    value: 'value',
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'actions',
    sortable: false,
    width: '100px',
  },
]

const PROVIDE_KEY = "SPREADSHEET_VIEW";

export default defineComponent({
  name: 'SpreadsheetsView',
  components: {
    XTextField,
    XBtn,
    VariablesTable,
    PageHeadline,
    VIntersectionObserver,
    VariableRow,
    HelpButton,
  },
  setup() {
    const {
      fetchVariables,
      updateOldVariable,
      isNewVariable,
      createVariable,
      deleteVariable,
      variables,
      isLastPage,
      addNewVariableToList,
      searchText,
      addBlankNewVariable
    } = useVariables()
    const { hasUnsavedChanges } = useLeavePageWarning();

    const newVariableId = ref(-1)
    const resetNewVariableId = () => {
      newVariableId.value = -1
    }

    const updateVariable = async (variable) => {
     if (isNewVariable(variable)) {
        const _v = await createVariable(variable, { updateList: false })
        newVariableId.value = _v.id
        addNewVariableToList(_v)
      } else {
        return updateOldVariable(variable)
      }
    };

    provide(PROVIDE_KEY, {
      updateVariable,
      deleteVariable,
      hasUnsavedChanges,
    });

    onBeforeMount(async () => {
      await fetchVariables()
    })

    return {
      headers,
      variables,
      fetchVariables,
      addBlankNewVariable,
      isLastPage,
      newVariableId,
      resetNewVariableId,
      searchText,
    }
  },
  methods: {
    onVariableRowMount() {
      if (this.newVariableId < 0) {
        return
      }
      this.$nextTick(() => {
        this.resetNewVariableId()
      })
    }
  }
});
</script>

<style lang="scss">
.spreadsheets-view {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  &__table-slot {
    flex-grow: 1;
    overflow-y: auto;
  }
}

</style>
