<template>
  <v-expansion-panel hover tile focusable multiple dense>
    <v-expansion-panel-header
        class="sortableHeader accordionHeader stepHeadColor"
        :id="stepType  + '_' + stepID + '_stepHeader'"
    >
      <stepHeader
          :result="result"
          :stepType="stepType"
          :stepID="stepID"
          :header="header"
          :additionalStepsInfo="additionalStepsInfo"
          :stepStoredValue="stepStoredValue"
          :ipcanStepTemplates="ipcanStepTemplates"
          :groupedStepDependentInfo="groupedStepDependentInfo"
          :templateValue="templateValue"
          :localData="localData"
          @click="checkClicked(...arguments)"
          :forWhat="forWhat"
          :groupValue="groupValue"
      ></stepHeader>
    </v-expansion-panel-header>
    <v-expansion-panel-content
        :id="stepID + '_' + stepType + '_stepContainer'"
        data-is-rendered="false"
        :data-stepid="stepID"
        class="step testLevel application"
        :data-step-identifier="stepIdentifier"
        :eager="stepType === 'use_stencil'">
      <div>
        <i
            :data-test-step="stepValue"
            data-help-link="stepHelpFlag"
            class="step-help fas fa-question-circle fs16 stepHelp"
        ></i>
      </div>

      <div class="mt-3 requestTable">
        <conditionContainer
          v-model="condition"
          :result="result"
        >
           <groupCombobox
              :options="groupedType"
              :value="stepTypeSelectorValue"
              @input="updateTypeValue(...arguments)"
              :result="result"
              :id="'stepSelector_' + stepID"
              :stepType="stepType"
              :label="'Step Type'"
          ></groupCombobox>
<!--
 Now we can add sub group title as well
 <groupCombobox
              :options="customOpt"
              :value="stepTypeSelectorValue"
              @input="updateTypeValue(...arguments)"
              :result="result"
              :id="'stepSelector_' + stepID"
              :stepType="stepType"
              :label="'Step Type'"
          ></groupCombobox>-->
        </conditionContainer>
        <div class="mt-5">
          <dynamicComponent
              v-if="typeof templateName != 'undefined' && templateName != null"
              :type="templateName"
              :result="result"
              :additional="{
              stepStoredValue: stepStoredValue,
              stepType: stepType,
              groupedStepDependentInfo: groupedStepDependentInfo,
              additionalStepsInfo: additionalStepsInfo,
              stepID: stepID,
              ipcanStepTemplates: ipcanStepTemplates,
              isValid: isValid,
            }"
              @stored-value-changed="processStepChanged(...arguments)"
              @update-additional-steps-info="updateAdditionalStepsInfo"
              @local-data-changed="updateLocalData"
              @revalidate="$emit('revalidate')"
              @changeIsValid="$emit('changeIsValid', $event)"
              @update:header="header = $event"
              @copy-stencil-steps="copyStencilSteps(...arguments)"
          ></dynamicComponent>
        </div>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import conditionContainer from "@/components/legacy/conditionContainer.vue";
import stepHeader from "@/components/legacy/stepHeader.vue";
import dynamicComponent from "@/commonComponents/dynamicComponent.vue";
import groupCombobox from "@/commonComponents/groupCombobox.vue";
import protocolsMapping from '@/cfg/protocolsMapping.json';

import {
  getSubstepMapping,
  checkDiameterProtocol,
  getFilteredTestCaseTypes,
} from "@/js/testHelpers.js";

export default {
  components: {
    conditionContainer,
    stepHeader,
    dynamicComponent,
    groupCombobox,
  },
  props: [
    "stepID",
    "fieldsObject",
    "stepIdentifier",
    "stepValue",
    "step",
    "stepIncrement",
    "result",
    "groupedStepDependentInfo",
    "templateValue",
    "isValid",
    "forWhat",
    "groupValue"
  ],
  data: function () {
    return {
      protocolsMapping:protocolsMapping,
      localData: null,
      header: '',
      /*customOpt:  [
      {
        "text": "Common",
        "header": "Common"
      },
      {
        "value": "register_time",
        "text": "Register Time (in ns)"
      },
      {
        "value": "operator",
        "text": "Operator"
      },
      {
        "divider": true
      },
      {
        "text": "Location",
        "header": "Main Header"
      },
      {
        "value": "MCC",
        "text": "MCC"
      },
      {
        "value": "MNC",
        "text": "MNC"
      },
        {
          "value": "MsdNC",
          "subHeader": "Second Header",
          "group": "Second Header"
        },
        {
          "value": "dsdsd",
          "text": "sub bb1",
          "subItem":true
        },
        {
          "value": "sdsd",
          "text": "sub bb2",
          "subItem":true
        },
      {
        "divider": true
      },
        {
          "text": "Main Header",
          "header": "Main Header"
        },
        {
          "value": "Main MCC",
          "text": "Main MCC"
        },
        {
          "value": "Main MNC",
          "text": "Main MNC"
        },

    ]*/

    };
  },
  computed: {
    ipcanStepTemplates: function () {
      let ipcanStepTemplates = {};
      if (
          typeof this.groupedStepDependentInfo != "undefined" &&
          typeof this.groupedStepDependentInfo["ipcanStepTemplates"] !=
          "undefined"
      ) {
        ipcanStepTemplates = this.groupedStepDependentInfo[
            "ipcanStepTemplates"
            ];
      }
      return ipcanStepTemplates;
    },
    localStepValue: {
      get() {
        //console.log(this.step);
        return this.step;
      },
      set(newVal) {
        this.$emit("step-value-changed", newVal);
      },
    },
    condition: {
      get() {
        let condition = "";
        if (typeof this.stepStoredValue.Condition != "undefined") {
          condition = this.stepStoredValue.Condition;
        }
        return condition;
      },
      set(val) {
        console.log("step",{val})
        this.$set(this.stepStoredValue, "Condition", val);
        this.processStepChanged(this.stepStoredValue);
      },
    },
    groupedType: function () {
      let options = [];
      for (let [key, value] of Object.entries(this.testCaseTypes)) {
        if (value.length > 0) {
          let headerText = "";
          value.forEach(function (stepInfo) {
            headerText += " " + stepInfo.text;
          });
          let header = this.languagePack("menu_" + key);
          if (key == "2G/3G" || key == "4G" || key == "5G") {
            header += " (shows configured items)";
          }
          options.push({
            text: headerText,
            header: header,
          });
          let text="";
          value.forEach(function (stepInfo) {
            text = protocolsMapping[stepInfo.value]
            if (key == "2G/3G" || key == "4G" || key == "5G") { // these cases PHP have some manipulated values
              text = stepInfo.text;
            }
            stepInfo = {
              ...stepInfo,
              selector: stepInfo.value + stepInfo.subtype,
              text: text
            };
            options.push(stepInfo);
          });
          options.push({divider: true});
        }
      }
      return options;
    },
    stepType: {
      get() {
        let stepType;
        let stepKeys = Object.keys(this.localStepValue);
        if (stepKeys.indexOf("stepIdentifier") > -1) {
          stepKeys.splice(stepKeys.indexOf("stepIdentifier"), 1);
        }
        if (typeof stepKeys[0] != "undefined") {
          stepType = stepKeys[0];
        }
        return stepType;
      },
    },
    stepTypeSelectorValue: {
      get() {
        let returned = "";
        let stepType = this.stepType;
        if (stepType != "" && typeof this.step[stepType] != "undefined") {
          let subType = this.step[stepType].subType;
          if (typeof subType == "undefined" || subType == "") {
            let mapping = getSubstepMapping();
            if (typeof mapping[stepType] != "undefined") {
              subType = mapping[stepType];
            }
          }
          this.groupedType.forEach(function (stepInfo) {
            if (subType == "") {
              if (stepInfo.value == stepType) {
                returned = stepInfo;
                return;
              }
            } else if (
                stepInfo.value == stepType &&
                stepInfo.subtype == subType
            ) {
              returned = stepInfo;
              return;
            }
          });
        }
        return returned;
      },
    },
    stepStoredValue: {
      get: function () {
        let stepStoredValue = {};
        let stepKeys = Object.keys(this.localStepValue);
        if (stepKeys.indexOf("stepIdentifier") > -1) {
          stepKeys.splice(stepKeys.indexOf("stepIdentifier"), 1);
        }
        let stepType = stepKeys[0];
        if (typeof this.step[stepType] != "undefined") {
          stepStoredValue = this.step[stepType];
        }
        return stepStoredValue;
      },
      set(stepObject) {
        this.updateStep(stepObject);
      },
    },
    templateName: function () {
      let stepType = this.stepType;
      if (typeof stepType != "undefined") {
        if (this.stepType.indexOf("RATTUX_") == 0) {
          return "step_RATTUX";
        } else if (this.stepType.indexOf("Explorer_VF_") === 0 || this.stepType === "Explorer_Activate_Modem") {
          return "step_Explorer_VF";
        } else if (
            checkDiameterProtocol(stepType) ||
            stepType == "IPCAN" ||
            stepType == "DCCA-Session" ||
            stepType == "RADIUS_ACCESS" ||
            stepType == "RADIUS_ACCOUNTING" ||
            stepType == "RADIUS"
        ) {
          return "step_IPCAN";
        }
        return "step_" + this.stepType;
      } else {
        return null;
      }
    },
    additionalStepsInfo: {
      get() {
        return this.result.additional.additionalStepsInfo;
      },
      set(newVal) {
        let localResult = {...this.result};
        this.$set(localResult.additional, "additionalStepsInfo", newVal);
      },
    },
    testCaseTypes() {
      let storedTestCaseTypes = this.result.additional.testCasesOriginal;
      let testCaseTypes = getFilteredTestCaseTypes(
          this.result.valueLine.subscriberType,
          storedTestCaseTypes,
          this.result.additional.noSubscriberAllowedTypes
      );
      return testCaseTypes;
    },
  },
  methods: {
    updateLocalData(localData) {
      this.localData = localData;
    },
    updateStep(stepObject) {
      let localStep = {...this.step};
      this.$set(localStep, this.stepType, stepObject);
      this.$emit("step-value-changed", localStep);
    },
    updateAdditionalStepsInfo(value) {
      this.additionalStepsInfo = value;
    },
    checkClicked(value) {
      if (value == "trash") {
        this.$emit("remove-step");
      } else if (value == "insertnext") {
        this.$emit("insert-next-step");
      } else if (value == "copyStep") {
        this.$emit("copy-step");
      }
    },
    copyStencilSteps(value) {
      this.$emit('copy-stencil-steps', value);
    },
    processStepChanged(value) {
      this.updateStep(value);
    },
    updateTypeValue(selectedValue) {
      let stepKeys = Object.keys(this.localStepValue);
      let stepValue = {...this.localStepValue};
      if (stepKeys.indexOf("stepIdentifier") > -1) {
        stepKeys.splice(stepKeys.indexOf("stepIdentifier"), 1);
      }
      let stepType = stepKeys[0];
      this.$delete(stepValue, stepType);
      this.$set(stepValue, selectedValue.value, {
        subType: selectedValue.subtype,
        category: selectedValue.category,
      });
      this.localStepValue = stepValue;
    },
    handleUpdateHeader(value) {
      console.log(value);
    },
  },
};
</script>
