<template>
  <v-card>
    <windowsFormHeader :result="result"> </windowsFormHeader>
    <v-form ref="testView">
      <v-card-text>
        <testInfoUpperPart
          :result="result"
          @update-search="updateLocalSearch"
        >
        </testInfoUpperPart>

        <div v-bind="result.formTableAttrs">
          <br />
          <!-- <tableScript></tableScript> -->
          <div class="tableContent">
            <div class="confirmationDialog"></div>
            <div id="messageDialog"></div>
            <div id="viewArea">
              <tableView
                :currentTable="currentTable"
                :result="result"
                :search="localSearch"
                @row-item-clicked="checkElementClicked(...arguments)"
                :hidePaging="true"
              ></tableView>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="test-info-view-old__sticky-btn-box">
        <fieldsButtons
          :result="result"
          @submitted="checkClicked(...arguments)"
        ></fieldsButtons>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import windowsFormHeader from "@/commonComponents/windowsFormHeader.vue";
import tableView from "@/commonComponents/tableView.vue";
import fieldsButtons from "@/commonComponents/fieldsButtons.vue";
import testCaseInfoService from "@/js/services/TestCaseInfoService.js";

import testInfoUpperPart from "@/components/legacy/testInfoUpperPart.vue";

export default {
  components: {
    tableView,
    fieldsButtons,
    windowsFormHeader,

    testInfoUpperPart,
  },
  props: ["result"],
  data: function () {
    return {
      localSearch: "",
    };
  },
  computed: {
    tablesData() {
      return this.$store.state.tablesData
    },

    currentTable: {
      get() {
        return this.tablesData[this.result.table.id];
      },
      set(tableValue) {
        this.$emit("table-changed", tableValue);
      },
    },
  },

  created() {
    const testCaseId = this.$route.query.n_id
    testCaseId && this.checkIfIsExplorerTest(testCaseId).then(this.correctLinkIfExplorer)
  },

  methods: {
    correctLinkIfExplorer(isExplorerTest) {
      if (!isExplorerTest) {
        return;
      }

      const START_TEST_RAW_TYPE = "STARTTEST"
      const data = this.currentTable.filteredData ? this.currentTable.filteredData : this.currentTable.data;

      for (const row of data) {
        if (row.rawType === START_TEST_RAW_TYPE) {
          row.c_messages.link = `?f=testing&f2=testsGroup&groupID=${this.result.fields.n_testgroup_id.value}`
          break
        }
      }
    },
    updateLocalSearch(value) {
      this.localSearch = value;
    },
    checkElementClicked(rowindex, row, actionName) {
      let currentTable = this.result.table;

      rowindex = currentTable.data.findIndex(
        (element) => element.order == row.order
      );
      if (actionName == "next") {
        this.getNext(rowindex + 1, currentTable);
      } else {
        this.getPrev(rowindex - 1, currentTable);
      }
    },
    getNext(rowindex, currentTable) {
      if (typeof currentTable.data[rowindex] == "undefined") {
        this.getNext(0, currentTable);
      } else {
        if (currentTable.data[rowindex]["packetDirection"] == "") {
          this.getNext(rowindex + 1, currentTable);
        } else {
          //this.$set(currentTable.data[rowindex], "openInfoDialog", true);
          this.$store.commit("openInfoDialog", currentTable.data[rowindex].order);
        }
      }
    },
    getPrev(rowindex, currentTable) {
      if (typeof currentTable.data[rowindex] == "undefined") {
        this.getPrev(currentTable.data.length - 1, currentTable);
      } else {
        if (currentTable.data[rowindex]["packetDirection"] == "") {
          this.getPrev(rowindex - 1, currentTable);
        } else {
          this.$set(currentTable.data[rowindex], "openInfoDialog", true);
          // this.$store.commit("tableChanged", currentTable);

          this.$store.commit("openInfoDialog", currentTable.data[rowindex].order);
        }
      }
    },

    checkClicked(functionName) {
      this.ajaxSubmit(
        functionName,
        this.$refs.testView.$el,
        this.result.tableUrl,
        this.result
      );
    },

    checkIfIsExplorerTest(testQueueId) {
      return new Promise((resolve, reject) => {
        testCaseInfoService.isExplorerTestCase(
          testQueueId,
          (isExplorerTest) => resolve(isExplorerTest),
          (_e, status) => {
            if (status === 404) {
              return resolve(false)
            }
            reject(_e)
          }
        )
      })
    }
  },
};
</script>

<style lang="scss">
:root {
  --v-application-wrap-display: flex;
  --v-application-wrap-overflow: initial;
}

.test-info-view-old {
  &__sticky-btn-box {
    position: sticky;
    bottom: 0px;
    background-color: white;
    z-index: 2;
  }
}
</style>
