<template>
  <div class="test-status-view">
    <XDataTable
        v-model="items"
        class="test-status-view__table"
        :items-request="cockpitTestStatusService.getTestStatus"
        :items-request-params="itemsRequestParams"
        :headers="headers"
        no-top
        :height="height"
        :no-elevation="!header"
        :title="tableTitle"
        :range="range"
        :custom-range="computedRange"
        :refresh="refresh"
        :no-page-controls="noPageControls"
        :items-per-page="itemsPerPage"
        :search-text="computedSearchText"
        :search="header"
        @search="dataSearchText = $event"
        @loading="$emit('loading', $event)"
        @refresh="() => getTestStatusCounts()"
    >
      <template #before-search>
        <div v-if="header && range">
          <DateTimeRange v-model="dataRange" range-default-time="8600"/>
        </div>
      </template>

      <template #above-table>
        <div class="test-status-view__header">
          <div class="test-status-view__upper-header">
            <div class="test-status-view__title">
              <span v-if="title">{{ title }}</span>

              <XBtn
                  v-if="dataSearchText"
                  color="primary"
                  text="Clear filter"
                  icon="mdi-filter-remove"
                  @click="dataSearchText = ''"
                  small
              />
            </div>

            <div class="test-status-view__top-right-area">
              <DateTimeRange v-if="!header && range" v-model="dataRange" range-default-time="8600"/>
              <TestStatusLegend v-if="showLegend"/>
            </div>
          </div>

          <div class="test-status-view__status-bar">
            <XBtn
              class="test-status-view__total-button"
              :text="statusTotal.text"
              height="25"
              :color="statusesFilter.length ? `${statusTotal.color} lighten-3` : `${statusTotal.color}`"
              elevation="0"
              @click="() => selectTotalStatuses()"
            />

            <SplitButton
              class="test-status-view__statuses-split-btn"
              v-model="statusesFilter"
              :values="statusesCounts"
              :splits="statusesRepr"
              :total="statusesCounts?.total || 0"
              multiple
            />
          </div>
        </div>
      </template>

      <template #[`item.groupName`]="{ value, row }">
        <v-hover>
          <template #default="{ hover }">
            <div class="test-status-view__group-name">
              <v-icon
                v-if="hover && header"
                class="test-status-view__search-for-name-lens"
                @click="dataSearchText = value"
              >
                mdi-magnify
              </v-icon>

              <router-link
                class="test-status-view__group-link"
                :to="getTestGroupPath(row.id)"
              >
                <span v-if="row.groupStartType === 1">{{ parseUmlauts(value) }}</span>

                <span v-else>{{ `${parseUmlauts(value)} (${parseUmlauts(row.user.firstName)} ${parseUmlauts(row.user.lastName)})` }}</span>
              </router-link>
            </div>
          </template>
        </v-hover>
      </template>

      <template #[`item.testsStatus`]="{ value, row }">
          <div class="test-status-view__status-bar">
            <XBtn
              class="test-status-view__total-button"
              :text="value.total"
              height="25"
              color="dark"
              elevation="0"
              :to="getTestGroupPath(row.id)"
            />

            <SplitButton
              class="test-status-view__statuses-split-btn"
              :values="value"
              :splits="getStatusesSplitsForTest(value)"
              :to-path="(split) => getTestGroupPath(row.id, split.value)"
            />
          </div>
      </template>
    </XDataTable>
  </div>
</template>

<script>
import { defineComponent, shallowRef } from "vue"
import XDataTable from '@/components/basic/XDataTable.vue';
import TestStatusLegend from '@/commonComponents/dynamic/testStatusLegend.vue';
import DateTimeRange from '@/components/basic/DateTimeRange.vue';
import {shaKey} from '@/js/helper';
import SplitButton from '@/components/basic/SplitButton.vue';
import XBtn from '@/components/basic/XBtn.vue';
import cockpitTestStatusService from "@/js/services/CockpitTestStatusService";
import {getUnixFromToByRange, parseUmlauts, unixToDateTimeString} from '@/js/general';
import { useStatusBarSelect } from "@/composition/filtering-components/use-status-bar-select"
import { GROUP_START_TYPES } from "@/composition/explorer-tests/config"

export default defineComponent({
  name: 'TestStatus',

  components: {
    SplitButton,
    XBtn,
    DateTimeRange,
    XDataTable,
    TestStatusLegend,
  },

  props: {
    title: String,
    range: Boolean,
    customRange: Object,
    refresh: Number,
    noPageControls: Boolean,
    itemsPerPage: Number,
    showTestName: {
      type: Boolean,
      default: true,
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
    searchText: String,
    height: [Number, String],
    header: Boolean,
    onlyScheduled: Boolean,
  },

  setup() {
    const {
      statusesCounts,
      statusesFilter,
      statusesRepr,
      statusTotal,
      generateSplits,
      selectTotalStatuses,
      statusesAsParamString,
    } = useStatusBarSelect({
      multiple: true,
    });

    const defaultSplits = shallowRef(generateSplits({
      getText: () => void 0,
    }))

    const getStatusesSplitsForTest = (testsStatus) => {
      return defaultSplits.value.filter(s => Boolean(testsStatus[s.key]))
    };

    return {
      selectTotalStatuses,
      statusesCounts,
      defaultSplits,
      statusesFilter,
      statusesRepr,
      statusTotal,
      statusesAsParamString,
      getStatusesSplitsForTest,
    }
  },

  data() {
    return {
      customHeight: 200,
      items: [],
      dataRange: {
        seconds: 86400,
      },
      dataSearchText: '',
      statusFilter: -1,
      cockpitTestStatusService: cockpitTestStatusService,
      parseUmlauts: parseUmlauts,
    };
  },

  watch: {
    dataSearchText(value) {
      this.$store.commit('setTestStatusSearch', value);
    },

    computedSearchText() {
      this.getTestStatusCounts();
    },

    computedRange() {
      this.getTestStatusCounts();
    },
  },

  created() {
    if (this.header) this.dataSearchText = this.$store.state.testStatusSearch;
    this.getTestStatusCounts();
  },

  computed: {
    itemsRequestParams() {
      return [
        !this.onlyScheduled ? -1 : 1, // -1 stands for nothing, 1 stands for scheduled
        this.statusesAsParamString,
      ];
    },

    headers() {
      const headers = [
        {
          text: 'Start',
          value: 'startTime',
          formatter: (value) => {
            return unixToDateTimeString(value);
          },
          width: 154,
        },
        {
          text: 'Type',
          value: 'groupStartType',
          width: 100,
          formatter: (value) => {
            return GROUP_START_TYPES[value];
          },
        },
        {
          text: 'Name',
          value: 'groupName',
          width: 500,
        },
        {
          text: 'Status',
          value: 'testsStatus',
          width: '100%',
        },
      ];

      if (this.onlyScheduled) {
        headers.splice(1, 1);
      }

      if (!this.showTestName) {
        headers.splice(headers.findIndex(x => x.value === 'groupName'), 1);
      }

      return headers;
    },

    tableTitle() {
      if (this.header) return 'Test Status';
      return undefined;
    },

    computedRange() {
      if (this.customRange && !this.range) return this.customRange;
      return this.dataRange;
    },

    computedSearchText() {
      if (this.searchText) return this.searchText;
      return this.dataSearchText;
    },
  },

  methods: {
    getTestGroupPath(groupId, status = -1) {
      const testQueueId = this.items.find(x => x.id === groupId).testQueueId;
      if (testQueueId) {
        return {
          name: "test-info",
          params: {
            id: testQueueId,
            idKey: shaKey(testQueueId.toString()),
          }
        };
      }

      const route = {
        name: "test-group",
        params: {
          id: groupId,
        }
      }
      if (status > 0) {
        route.query = { status };
      }
      return route;
    },

    async getTestStatusCounts() {
      const fromTo = getUnixFromToByRange(this.computedRange);
      this.statusesCounts = await cockpitTestStatusService.getTestStatusCounts(fromTo.from, fromTo.to, this.computedSearchText)
    },

    handleCountsSplitClick(key, value, status) {
      this.statusesFilter = status;
    },
  },
});
</script>

<style lang="scss">
.test-status-view {
  height: 100%;

  &__table {
    height: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__upper-header {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 18px;
  }

  &__total-button {
    flex: 0 0 auto;

    .v-btn:not(.v-btn--round).v-size--default {
      color: var(--v-text-inverted-base);
      font-size: 12px;
    }
  }

  &__status-bar {
    display: flex;
    width: 100%;
    gap: 2px;
    align-items: center;
  }

  &__statuses-split-btn {
    flex: 1;
  }

  p {
    font-family: sans-serif;
  }

  &__group-name {
    position: relative;
  }

  &__search-for-name-lens.v-icon {
    position: absolute;
    left: -24px;
    cursor: pointer;
  }

  &__top-right-area {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
</style>
