<template>
  <div class="table-bottom-controls">
    <div 
      v-if="table['selectable'] === true"
      id="selectAllContainer"
    >
      <v-checkbox
        id="selectAll"
        label="Select All"
        color="primary"
        v-model="selectAll"
        onclick="selectAllVisible(this);"
        class="selectAllCheckBox"
      />
    </div>

    <div v-if="table['deletable'] === 'true'">
      <button
        class="table-bottom-controls__del-btn"
        @click="() => emitDeleteSelected()"
      >
        <v-icon class="table-bottom-controls__del-ico">
          mdi-delete
        </v-icon>

        Delete
      </button>
    </div>

    <div class="table-bottom-controls__additional-btns">
      <dynamicComponent
        v-for="type in table['additionalSelectableButtons']"
        :key="type"
        :type="type"
        :result="result"
        :additional="{ selectedRows: selectedRows, table: table }"
        @show-warning="setupWarning"
        v-on="$listeners" 
      />

      <AlmUpload
        v-if="typeof result!=='undefined' && typeof result.alm!=='undefined' && result.alm"
        class="table-bottom-controls__upload-btn"
        :test-queue-ids="selectedRowIds" 
      />
    </div>

    <warning
      v-show="showWarning"
      :message="warningMessage"
    />
  </div>
</template>

<script>
import dynamicComponent from '@/commonComponents/dynamicComponent.vue';
import warning from '@/components/legacy/warning.vue';
import AlmUpload from '@/components/specific/AlmUpload.vue';

export default {
  props: ['table', 'allSelected', 'result', 'selectedRows'],
  components: {
    AlmUpload,
    dynamicComponent,
    warning,
  },
  data: function () {
    return {
      warningMessage: 'Please select at least one row!',
      showWarning: false,
    };
  },
  computed: {
    customClass(){
      let vClass = "ml-9";
      if(typeof this.table['additionalSelectableButtons']!=='undefined' &&  this.table['additionalSelectableButtons'].length > 3 ){
        vClass = "ml-3";
      }
      return vClass;
    },
    selectAll: {
      get() {
        return this.allSelected;
      },
      set(val) {
        this.$emit('all-selected', val);
      },
    },
    selectedRowIds() {
      const selectedRowIds = [];
      for (const id of Object.keys(this.selectedRows)) {
        selectedRowIds.push(parseInt(id));
      }
      return selectedRowIds;
    },
  },
  methods: {
    setupWarning(value) {
      this.showWarning = value;
      setTimeout(() => {
        this.showWarning = false;
      }, 3000);
    },
    emitDeleteSelected() {
      this.$emit('delete-selected');
    },
  },
};
</script>

<style lang="scss">
.table-bottom-controls {
  display: flex;
  align-items: center;
  gap: 1rem;

  &__del-btn {
    color: rgba(0,0,0,.6);
  }

  &__del-ico {
    margin-right: .5rem;
  }

  &__additional-btns {
    display: flex;
    align-items: center;
  }

  &__upload-btn {
    margin-top: 3px;
    margin-left: 10px;
  }
}
</style>
