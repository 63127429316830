  <template>
  <div>
    <TagQueryTextField
        allowTestVariables
        required
        @update:is-valid="$emit('changeIsValid', $event)"
        type="sim"
        :label=result?.mainContent?.fields?.pingLine?.fields?.tagSyntax?.label
        v-model="tagSyntax"
        current-project
        @count="tagQuerySimCount = $event"
        id="Explorer_insert_sim_tag_query"/>
    <div v-if="tagQuerySimCount !== undefined" class="text-right">
      SIMs: {{ tagQuerySimCount }}
    </div>
  </div>
</template>
<script>
import TagQueryTextField from '@/components/extended/TagQueryTextField';

export default {
  components: {
    TagQueryTextField,
  },
  props: ['result'],
  data() {
    return {
      tagQuerySimCount: undefined,
      isValid : true
    };
  },
  computed: {
    tagSyntax: {
      get() {
        let tagSyntax = '';
        if (this.result?.valueLine?.tagSyntax) {
          tagSyntax = this.result.valueLine.tagSyntax;
        }
        return tagSyntax;
      },
      set(newValue) {
        this.$emit('field-value-changed', 'tagSyntax', newValue);
      },
    },
  },
};
</script>