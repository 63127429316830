import { onBeforeMount, onBeforeUnmount, ref } from "vue"
import { useRouter } from "vue-router/composables"

export function useLeavePageWarning({ message = "You have unsaved changes. Do you want to leave?" } = {}) {
  const router = useRouter();
  const hasUnsavedChanges = ref(false);
  let removeRouterGuard = null;

  const handleBeforeUnload = (event) => {
    if (hasUnsavedChanges.value) {
      event.preventDefault();
      event.returnValue = "";
    }
  }

  const handleRouterGuard = (_, __, next) => {
    if (hasUnsavedChanges.value) {
      if (window.confirm(message)) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  };

  onBeforeMount(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    removeRouterGuard = router.beforeEach(handleRouterGuard);
    console.log({ removeRouterGuard });
  });

  onBeforeUnmount(() => {
    window.removeEventListener("beforeunload", handleBeforeUnload);
    if (removeRouterGuard) removeRouterGuard();
  });

  return {
    hasUnsavedChanges
  };
}
