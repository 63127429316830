<script setup>
import { computed, defineEmits, defineProps } from "vue";
import PrimaryTextfield from "@/commonComponents/primaryTextfield.vue";
import DefaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
import OnTimeoutNoResult from "@/components/legacy/onTimeOutNoResult.vue";
import TextFieldAsyncValidation from "@/components/extended/TextFieldAsyncValidation.vue";
import { conditionCheckService } from "@/api";

const props = defineProps({
  result: {
    type: Object,
    default: () => ({}),
  },
  stepStoredValue: {
    type: Object,
    default: () => ({}),
  },
  stepType: String,
  groupedStepDependentInfo: Object,
  stepID: String,
  additionalStepsInfo: Object
});

const emit = defineEmits(['stored-value-changed']);

const waitValue = computed({
  get() {
    // must be a number, but to trigger error text, pass object!!!!
    return props.stepStoredValue?.wait || {};
  },
  set(value) {
    updateStoredValue('wait', value)
  }
});

const description = computed(() => {
  return props.stepStoredValue?.description || "";
});

const condition = computed(() => {
  return props.stepStoredValue?.wait_condition || "";
});

const timeoutValue = computed({
  get() {
    return props.stepStoredValue?.onTimeout || {};
  },
  set(value) {
    updateStoredValue('onTimeout', value);
  }
});

const handleDescriptionInput = (value) => {
  updateStoredValue('description', value);
};

const handleConditionUpdate = (value) => {
  updateStoredValue('wait_condition', value);
};

const validateCondition = async (expression) => {
  const { valid, message } = await conditionCheckService.v1.checkSyntax({ expression });
  return valid ? "" : message;
};

const updateStoredValue = (field, value) => {
  const updatedValue = Object.assign({}, props.stepStoredValue)
  updatedValue[field] = value
  emit("stored-value-changed", updatedValue)
};
</script>

<template>
  <div>
    <primary-textfield
      type="number"
      :field-attr-input="{ class:'number required', id:'step_wait_input'}"
      label="Wait"
      v-model="waitValue"
      :template-content="result"
    />

    <default-field-types
      :field-attributes="{
        type: 'textarea',
        name: 'Description',
        fieldAttrInput: {
          id: 'step_wait_description_input',
          class: '',
          rows: 2,
          autofocus: true,
          'auto-grow': true
        }
      }"
      @input="handleDescriptionInput"
      :template-content="result"
      :value="description"
    />

    <TextFieldAsyncValidation
      label="Wait Condition"
      :model-value="condition"
      @update:modelValue="handleConditionUpdate"
      :validator="validateCondition"
      hints="Example: ${step:subscriber} == '49123456789' AND (${LOCATION} != '26202' OR '3GPP-SGSN-MCC-MNC' == ${MCCMNC})"
      help-id="step_wait_condition_input"
      is-required
      tag="textarea"
      rows="5"
    />

    <on-timeout-no-result
      on-event="On Timeout"
      v-model="timeoutValue"
    />
  </div>
</template>

