<template>
  <div class="mb-2 pa-3">
    <div :style="{ 'max-width' :tableWidth+'px' }">
      <v-row class="primary white--text">
        <template v-for="(value,index) in newItems">
          <v-col
              :key="index"
              class="pa-1 table-index-column text-center"
              style=""
          > {{ customIndexName(index) }}
          </v-col>
        </template>
      </v-row>
      <v-row style="border: solid 1px #dbdbdb">
        <template v-for="(value,index) in newItems">
          <v-col
              :style="{ 'width' : colWidth+'px' }"
              :key="index"
              class="pa-1 table-value-column text-center"
          > {{ modemOutUniConversion(value, index) }}
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>
<script>
import {objectCustomSort, convertByte2MB, convertMBits} from "@/js/helper.js";

export default {
  props: {
    item: Object,
    type: String
  },
  data() {
    return {
      tableWidth: "700",
      directSecondsAttr: ['dialingTime', 'connectionTime', 'actionAfter'],
      colWidth: "75",
    };
  },
  computed: {
    newItems() {
      this.customColWidth(this.type);
      var sortOrder = [];
      let itemList = {};
      if (this.type == "Explorer_USSD") {
        sortOrder = ['code', 'timeout'];
        itemList['code'] = this.item?.code ? this.item.code : "";
        itemList['timeout'] = this.item?.timeout ? this.item.timeout : "";
      } else if (this.type == 'Explorer_SMS') {
        sortOrder = ['targetNumber', 'smsc', 'text', 'statusRequest'];
        if (this.item?.statusRequest?.length == 0) {
          delete this.item.statusRequest;
        }
        itemList = this.item;
      } else if (this.type == 'Explorer_cellular_voice_call') {
        sortOrder = ['type', 'targetNumber', 'dialingTime', 'connectionTime'];
        itemList = this.item;
      } else if (this.type == 'Explorer_cellular_receive_call') {
        sortOrder = ['type', 'number', 'connectionTime', 'actionAfter', 'action'];
        itemList = this.item;
      } else if (this.type == 'Explorer_cellular_attach') {
        sortOrder = ['ratType', 'network', 'simType', 'slot'];
        let ratType = "";
        if (this.item?.radio?.ratType) {
          ratType = this.getAttributeMapping("modemRatType", this.item.radio.ratType);
        }
        itemList['ratType'] = ratType;
        itemList['network'] = this.item?.network ? this.item.network : "";
        itemList['simType'] = this.item?.sim?.simType ? this.item.sim.simType : "";
        if (this.item?.sim?.slot) {
          itemList['slot'] = this.item.sim.slot;
        }
      } else if (this.type == 'Explorer_insert_sim') {
        sortOrder = ['simType', 'iccid', 'name'];
        itemList['simType'] = this.item?.simType ? this.item.simType : "";
        itemList['iccid'] = this.item?.iccid ? this.item.iccid : "";
        if (this.item?.name) {
          itemList['name'] = this.item.name;
        }
      } else if (this.type == 'Explorer_cellular_data_connect') {
        sortOrder = ['apn', 'user', 'password'];
        itemList['user'] = this.item?.user?.length > 0 ? this.item.user : "Not Set";
        itemList['password'] = this.item?.password?.length > 0 ? "*******" : "Not Set";
        itemList['apn'] = this.item?.apn?.length > 0 ? this.item.apn : "Not Set";
      } else if (this.type == 'Explorer_http_download') {
        sortOrder = ['host', 'downloadSize', 'streams'];
        if (this.item?.host?.trim() == '') {
          delete this.item.host;
        }
        itemList = this.item;
      } else if (this.type == 'Explorer_cellular_location') {
        sortOrder = ['CI', 'TAC', 'rat'];
        itemList['CI'] = this.item?.CI ? this.item.CI : "";
        itemList['TAC'] = this.item?.TAC ? this.item.TAC : "";
        itemList['rat'] = this.item?.rat ? this.item.rat : "";
      } else if (this.type == 'Explorer_cellular_start_voice_mo') {
        sortOrder = ['targetNumber', 'callId', 'dialingTime', 'type'];
        itemList['targetNumber'] = this.item?.targetNumber ? this.item.targetNumber : "";
        itemList['callId'] = this.item?.callId ? this.item.callId : "";
        itemList['dialingTime'] = this.item?.dialingTime ? this.item.dialingTime : "";
        itemList['type'] = this.item?.type ? this.item.type : "";
      } else if (this.type == 'Explorer_play_audio') {
        sortOrder = ['text', 'iterations', 'pauseBetweenIterations'];
        itemList['text'] = this.item?.text ? this.item.text : "";
        itemList['iterations'] = this.item?.iterations ? this.item.iterations : "";
        itemList['pauseBetweenIterations'] = this.item?.pauseBetweenIterations ? this.item.pauseBetweenIterations : "";
      } else if (this.type == 'Explorer_cellular_hangup_call') {
        itemList['callIds'] = this.item?.callIds?.toString() ? this.item.callIds.toString() : "";
      } else {
        itemList = this.item;
      }
      this.customWidth(this.type, itemList);
      return objectCustomSort(sortOrder, itemList);
    }
  },
  methods: {
    customIndexName(index) {
      let result = this.languagePack("basicModemTable", index);
      if (this.type == "Explorer_cellular_receive_call" && index == 'connectionTime') {
        result = "Hang Up After";
      }
      return result;
    },
    customColWidth(type) {
      if (type == 'Explorer_cellular_attach') {
        this.colWidth = "120";
      } else if (type == 'Explorer_http_download') {
        this.colWidth = "";// no min width
      }
    },
    modemOutUniConversion(value, index) {
      if (this.directSecondsAttr.includes(index)) {
        value = value == 1 ? value + " Second" : value + " Seconds";
      }
      if (this.type == 'Explorer_cellular_location') {
        value = this.getAttributeMapping("modemRatTypeActualMapping", value);
      } else if (this.type == 'Explorer_cellular_receive_call') {
        value = this.getAttributeMapping("voiceCallAction", value);
      } else if (this.type == 'Explorer_data_usage') {
        if (index == 'downloadSize') {
          value = convertByte2MB(value,'b',true);//binary and show "i" between
        } else if (index == 'limitBandwidth') {
          value = convertMBits(value);
        }
      }
      return value;
    },
    customWidth(type, itemList) {
      let attrLength = Object.keys(itemList).length;
      this.tableWidth = attrLength * 200;
    }
  }
};
</script>
<style scoped>
.table-index-column:not(:last-child) {
  border-right: thin solid white;
}

.table-value-column:not(:last-child) {
  border-right: thin solid #dbdbdb;
}

</style>