var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.computedTooltip)?_c('router-link',{class:{
    'clickable-txt-2__icon-link': true,
    'clickable-txt-2__icon-link--disabled': _vm.computedDisabled,
  },attrs:{"disabled":_vm.computedDisabled,"event":_vm.computedDisabled ? '' : 'click',"to":_vm.to}},[(_vm.computedIcon)?_c('v-icon',{attrs:{"disabled":_vm.computedDisabled,"color":_vm.computedIconColor}},[_vm._v(" "+_vm._s(_vm.computedIcon)+" ")]):_vm._e(),(_vm.computedText)?_c('span',[_vm._v(" "+_vm._s(_vm.computedText)+" ")]):_vm._e()],1):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('router-link',_vm._g(_vm._b({class:{
        'clickable-txt-2__icon-link': true,
        'clickable-txt-2__icon-link--disabled': _vm.computedDisabled,
      },attrs:{"to":_vm.to,"disabled":_vm.computedDisabled,"event":_vm.computedDisabled ? '' : 'click'}},'router-link',attrs,false),on),[(_vm.computedIcon)?_c('v-icon',{attrs:{"disabled":_vm.computedDisabled,"color":_vm.computedIconColor}},[_vm._v(" "+_vm._s(_vm.computedIcon)+" ")]):_vm._e(),(_vm.computedText)?_c('span',[_vm._v(" "+_vm._s(_vm.computedText)+" ")]):_vm._e()],1)]}}])},[_vm._v(" "+_vm._s(_vm.computedTooltip)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }