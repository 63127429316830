import api from "@acctopus-fe/api"
import { HttpClient } from "@acctopus-fe/http-client"
import { tokenRefresher } from "./auth-tools"

const apiErrorHandler = ({ error, url, options }) => {
  console.error("Error: ", error, "\n", "URL: ", url, "\n", "Options: ", options)
};

export const simService = api.create.simService(
  new HttpClient({
    // @ts-expect-error tokenRefresher is proxy to the original tokenRefresher
    tokenRefresher,
    defaultOptions: {},
    onError: apiErrorHandler,
  }),
  process.env.VUE_APP_COCKPIT_SIM_SERVICE || ""
);

export const authService = api.create.authService(
  new HttpClient({
    // @ts-expect-error tokenRefresher is proxy to the original tokenRefresher
    tokenRefresher,
    defaultOptions: {},
    onError: apiErrorHandler,
  }),
  process.env.VUE_APP_AUTH_SERVICE || ""
);

export const helpService = api.create.helpService(
  new HttpClient({
    // @ts-expect-error tokenRefresher is proxy to the original tokenRefresher
    tokenRefresher,
    defaultOptions: {},
    onError: apiErrorHandler,
  }),
  process.env.VUE_APP_HELP_SERVICE || ""
);

export const userService = api.create.userService(
  new HttpClient({
    // @ts-expect-error tokenRefresher is proxy to the original tokenRefresher
    tokenRefresher,
    defaultOptions: {},
    onError: apiErrorHandler,
  }),
  process.env.VUE_APP_USER_SERVICE || ""
);

export const cockpitExplorerService = api.create.cockpitExplorerService(
  new HttpClient({
    // @ts-expect-error tokenRefresher is proxy to the original tokenRefresher
    tokenRefresher,
    defaultOptions: {},
    onError: apiErrorHandler,
  }),
  process.env.VUE_APP_COCKPIT_EXPLORER_SERVICE || ""
);

export const conditionCheckService = api.create.conditionCheckService(
  new HttpClient({
    // @ts-expect-error tokenRefresher is proxy to the original tokenRefresher
    tokenRefresher,
    defaultOptions: {},
    onError: apiErrorHandler,
  }),
  process.env.VUE_APP_CONDITION_CHECK_SERVICE || ""
);

export const cockpitSpreadsheetService = api.create.cockpitSpreadsheetService(
  new HttpClient({
    // @ts-expect-error tokenRefresher is proxy to the original tokenRefresher
    tokenRefresher,
    defaultOptions: {},
    onError: apiErrorHandler,
  }),
  process.env.VUE_APP_COCKPIT_SPREADSHEET_SERVICE || ""
);
