<template>
  <div class="condition-container">
    <v-row class="stepTypeWrapper" no-gutters>
      <v-col style="max-width: 400px" class="inlineDiv typeSelectorWrapper">
        <slot></slot>
      </v-col>

      <v-col class="conditionButtonWrapper ml-4 d-flex" style="max-width: fit-content;">
        <v-switch
          v-model="isEnabled"
          label="Condition"
          :disabled="disabled"
          class="mt-1"
        ></v-switch>

        <HelpButton id="step_condition_switch"></HelpButton>
      </v-col>
    </v-row>

    <v-row 
      v-if="isEnabled"
      class="testConditionLine"
    >
      <v-col>
        <div class="dot"></div>

        <TextFieldAsyncValidation
          class="condition-container__text-field"
          label="Condition"
          :model-value="value"
          @update:modelValue="(cond) => updateCondition(cond)"
          :validator="validateCondition"
          :hints="language.componentLabel.test_conditions_hint"
          help-id="step_conditions_input"
          is-required
        />
      </v-col>
    </v-row>

    <v-divider></v-divider>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue"
import HelpButton from "@/components/basic/HelpButton.vue";
import TextFieldAsyncValidation from "@/components/extended/TextFieldAsyncValidation.vue" ;
import { conditionCheckService } from "@/api";

export default defineComponent({
  components: {
    HelpButton,
    TextFieldAsyncValidation
  },

  props: ["value", "result"],

  emits: ["input"],

  setup(props, { emit }) {
    const isEnabled = ref(props.value.length > 0);

    const validateCondition = async (expression) => {
      const { valid, message } = await conditionCheckService.v1.checkSyntax({ expression });
      if (valid) {
        return "";
      }
      return message;
    };

    const updateCondition = (condition) => {
      emit("input", condition);
    }

    return {
      validateCondition,
      isEnabled,
      updateCondition,
    };
  },

  computed: {
    disabled() {
      let disabled = this.value.length > 0;

      if (this.result?.disableAllInputs) {
        disabled = true;
      }

      return disabled;
    },
  },
});
</script>

<style lang="scss">
.condition-container {
  &__text-field {
    max-width: 760px;
  }
}
</style>
