<script>
export default {
  metaInfo: {
    title: "Testcase Search/Details",
  }
};
</script>

<script setup>
import PhpServiceView from '@/components/basic/php-wrappers/PhpServiceView.vue';
import TestcaseDetails from '@/components/dynamic/testcaseDetails.vue';
</script>

<template>
  <PhpServiceView url="?f=administration&f2=testcaseDetails" #default="{result}">
    <TestcaseDetails :result="result"/>
  </PhpServiceView>
</template>


