<template>
 <div> <XSelect
     :help="help"
     :disabled="disabled"
     :value="computedValue"
     :items="computedItems"
     chips
     class="tags"
     label="Tags"
     multiple
     @input="$emit('input', $event)"
     dense
     no-spaces-in-values
     combobox/>
 </div>
</template>

<script>
import XSelect from '@/components/basic/XSelect.vue';
import cockpitExplorerService from '@/js/services/CockpitExplorerService';

export default {
  name: 'TagSelect',
  components: {XSelect},
  props: {
    help: String,
    value: Array,
    items: Array,
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dataItems: [],
    };
  },
  created() {
    if (this.items === undefined) {
      cockpitExplorerService.getExplorerTags((tags) => {
        this.dataItems = tags;
      });
    }
  },

  computed: {
    computedValue() {
      return this.value.filter(e => String(e).trim());
    },
    computedItems() {
      if (this.items !== undefined) return this.items;
      return this.dataItems;
    },
  },
};
</script>

<style scoped>
.tags >>> .v-input__slot {
  min-height: 96px;
}
</style>