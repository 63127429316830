<script>
export default {
  metaInfo: {
    title: "Log View",
  }
};
</script>

<script setup>
import PhpServiceView from '@/components/basic/php-wrappers/PhpServiceView.vue';
import ServerLogView from '@/components/dynamic/serverLogView.vue';
</script>

<template>
  <PhpServiceView url="?f=administration&f2=logViewer" #default="{result}">
    <ServerLogView :result="result"/>
  </PhpServiceView>
</template>

