import { getTableParams } from "./get-table-params"

export const routes = [
  // ----
  // Home
  // ----
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "dashboard-view" */ "@/views/DashboardView.vue"),
    meta: {
      isAuthRequired: true,
    },
  },

  // ------
  // Public
  // ------
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login-view" */ "../views/public/LoginView.vue"),
    meta: {
      isAuthRequired: false,
    },
  },

  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register-view" */ "../views/public/RegisterView.vue"),
    meta: {
      isAuthRequired: false,
    },
  },

  {
    path: "/activate",
    name: "activate",
    component: () =>
      import(/* webpackChunkName: "activate-view" */ "../views/public/ActivateView.vue"),
    meta: {
      isAuthRequired: false,
    },
  },

  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password-view" */ "../views/public/ForgotPasswordView.vue"
      ),
    meta: {
      isAuthRequired: false,
    },
  },

  {
    path: "/reset-password",
    name: "reset-password",
    component: () =>
      import(/* webpackChunkName: "reset-password-view" */ "../views/public/ResetPasswordView.vue"),
    meta: {
      isAuthRequired: false,
    },
  },

  {
    path: "/reset-pass-notice",
    name: "reset-pass-notice",
    component: () =>
      import(
        /* webpackChunkName: "reset-pass-notice" */ "../views/public/ResetPassNoticeView.vue"
      ),
    meta: {
      isAuthRequired: false,
    },
  },

  {
    path: "/testing/configure-and-run-tests",
    name: "configure-and-run-tests",
    component: () =>
      import(
        /* webpackChunkName: "configure-and-run-tests-view" */ "../views/testing/ConfigureAndRunTestsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/testing/tests-execute-api",
    name: "tests-execute-api",
    component: () =>
      import(
        /* webpackChunkName: "tests-execute-api-view" */ "../views/testing/TestsExecuteApiView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/testing/schedule-tests",
    name: "schedule-tests",
    component: () =>
      import(
        /* webpackChunkName: "schedule-tests-view" */ "../views/testing/ScheduleTestsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/testing/test-status",
    name: "test-status",
    component: () =>
      import(/* webpackChunkName: "test-status-view" */ "../views/testing/TestStatusView.vue"),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/testing/explorer-tests",
    name: "explorer-tests",
    component: () =>
      import(
        /* webpackChunkName: "explorer-tests-view" */ "../views/testing/ExplorerTestsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/testing/currently-running-tests",
    name: "currently-running-tests",
    component: () =>
      import(
        /* webpackChunkName: "currently-running-tests-view" */ "../views/testing/CurrentlyRunningTestsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/testing/previous-tests",
    name: "previous-tests",
    component: () =>
      import(
        /* webpackChunkName: "previous-tests-view" */ "../views/testing/PreviousTestsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/testing/received-sms",
    name: "received-sms",
    component: () =>
      import(/* webpackChunkName: "received-sms-view" */ "../views/testing/ReceivedSmsView.vue"),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/testing/received-messages-kafka",
    name: "received-messages-kafka",
    component: () =>
      import(
        /* webpackChunkName: "received-messages-kafka-view" */ "../views/testing/ReceivedMessagesKafkaView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/testing/received-messages-cdr",
    name: "received-messages-cdr",
    component: () =>
      import(
        /* webpackChunkName: "received-messages-cdr-view" */ "../views/testing/ReceivedMessagesCdrView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/testing/received-packets",
    name: "received-packets",
    component: () =>
      import(
        /* webpackChunkName: "received-packets-view" */ "../views/testing/ReceivedPacketsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/testing/test-info/:id/:idKey",
    name: "test-info",
    component: () =>
      import(/* webpackChunkName: "test-info-view" */ "../views/testing/TestInfoView.vue"),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/testing/test-group/:id",
    name: "test-group",
    component: () =>
      import(/* webpackChunkName: "test-group-view" */ "../views/testing/TestGroupView.vue"),
    meta: {
      isAuthRequired: true,
    },
  },

  // -------------
  // Configuration
  // -------------
  // Outbound connections
  {
    path: "/configuration/outbound-connections/2g-3g/aaa",
    name: "oc-2g-3g-aaa",
    component: () =>
      import(
        /* webpackChunkName: "oc-2g-3g-aaa-view" */ "../views/configuration/outbound-connections/2g-3g/Oc2g3gAaaView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/aaa",
    name: "oc-4g-aaa",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-aaa-view" */ "../views/configuration/outbound-connections/4g/Oc4gAaaView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/abmf",
    name: "oc-4g-abmf",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-abmf-view" */ "../views/configuration/outbound-connections/4g/Oc4gAbmfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/af",
    name: "oc-4g-af",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-af-view" */ "../views/configuration/outbound-connections/4g/Oc4gAfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/eir",
    name: "oc-4g-eir",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-eir-view" */ "../views/configuration/outbound-connections/4g/Oc4gEirView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/gmlc",
    name: "oc-4g-gmlc",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-gmlc-view" */ "../views/configuration/outbound-connections/4g/Oc4gGmlcView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/hss",
    name: "oc-4g-hss",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-hss-view" */ "../views/configuration/outbound-connections/4g/Oc4gHssView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/mme",
    name: "oc-4g-mme",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-mme-view" */ "../views/configuration/outbound-connections/4g/Oc4gMmeView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/ocf",
    name: "oc-4g-ocf",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-ocf-view" */ "../views/configuration/outbound-connections/4g/Oc4gOcfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/ofcs",
    name: "oc-4g-ofcs",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-ofcs-view" */ "../views/configuration/outbound-connections/4g/Oc4gOfcsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/pcrf",
    name: "oc-4g-pcrf",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-pcrf-view" */ "../views/configuration/outbound-connections/4g/Oc4gPcrfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/pgw",
    name: "oc-4g-pgw",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-pgw-view" */ "../views/configuration/outbound-connections/4g/Oc4gPgwView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/sgw",
    name: "oc-4g-sgw",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-sgw-view" */ "../views/configuration/outbound-connections/4g/Oc4gSgwView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/slf",
    name: "oc-4g-slf",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-slf-view" */ "../views/configuration/outbound-connections/4g/Oc4gSlfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/spr",
    name: "oc-4g-spr",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-spr-view" */ "../views/configuration/outbound-connections/4g/Oc4gSprView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/4g/epdg",
    name: "oc-4g-epdg",
    component: () =>
      import(
        /* webpackChunkName: "oc-4g-epdg-view" */ "../views/configuration/outbound-connections/4g/Oc4gEpdgView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/eir",
    name: "oc-5g-eir",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-eir-view" */ "../views/configuration/outbound-connections/5g/Oc5gEirView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/af",
    name: "oc-5g-af",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-af-view" */ "../views/configuration/outbound-connections/5g/Oc5gAfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/amf",
    name: "oc-5g-amf",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-amf-view" */ "../views/configuration/outbound-connections/5g/Oc5gAmfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/ausf",
    name: "oc-5g-ausf",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-ausf-view" */ "../views/configuration/outbound-connections/5g/Oc5gAusfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/bsf",
    name: "oc-5g-bsf",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-bsf-view" */ "../views/configuration/outbound-connections/5g/Oc5gBsfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/chf",
    name: "oc-5g-chf",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-chf-view" */ "../views/configuration/outbound-connections/5g/Oc5gChfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/dwdaf",
    name: "oc-5g-dwdaf",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-dwdaf-view" */ "../views/configuration/outbound-connections/5g/Oc5gDwdafView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/gmlc",
    name: "oc-5g-gmlc",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-gmlc-view" */ "../views/configuration/outbound-connections/5g/Oc5gGmlcView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/hss",
    name: "oc-5g-hss",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-hss-view" */ "../views/configuration/outbound-connections/5g/Oc5gHssView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/lmf",
    name: "oc-5g-lmf",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-lmf-view" */ "../views/configuration/outbound-connections/5g/Oc5gLmfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/nef",
    name: "oc-5g-nef",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-nef-view" */ "../views/configuration/outbound-connections/5g/Oc5gNefView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/nrf",
    name: "oc-5g-nrf",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-nrf-view" */ "../views/configuration/outbound-connections/5g/Oc5gNrfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/nssf",
    name: "oc-5g-nssf",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-nssf-view" */ "../views/configuration/outbound-connections/5g/Oc5gNssfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/otaf",
    name: "oc-5g-otaf",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-taf-view" */ "../views/configuration/outbound-connections/5g/Oc5gOtafView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/pcf",
    name: "oc-5g-pcf",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-pcf-view" */ "../views/configuration/outbound-connections/5g/Oc5gPcfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/scef",
    name: "oc-5g-scef",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-scef-view" */ "../views/configuration/outbound-connections/5g/Oc5gScefView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/smf",
    name: "oc-5g-smf",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-smf-view" */ "../views/configuration/outbound-connections/5g/Oc5gSmfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/smsf",
    name: "oc-5g-smsf",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-smsf-view" */ "../views/configuration/outbound-connections/5g/Oc5gSmsfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/ucmf",
    name: "oc-5g-ucmf",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-ucmf-view" */ "../views/configuration/outbound-connections/5g/Oc5gUcmfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/udm",
    name: "oc-5g-udm",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-udm-view" */ "../views/configuration/outbound-connections/5g/Oc5gUdmView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/udr",
    name: "oc-5g-udr",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-udr-view" */ "../views/configuration/outbound-connections/5g/Oc5gUdrView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/5g/vae",
    name: "oc-5g-vae",
    component: () =>
      import(
        /* webpackChunkName: "oc-5g-vae-view" */ "../views/configuration/outbound-connections/5g/Oc5gVaeView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/fixed-net/bras",
    name: "oc-fixed-net-bras",
    component: () =>
      import(
        /* webpackChunkName: "oc-fixed-net-bras-view" */ "../views/configuration/outbound-connections/fixed-net/OcFixedNetBrasView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/application/ssh",
    name: "oc-application-ssh",
    component: () =>
      import(
        /* webpackChunkName: "oc-application-ssh-view" */ "../views/configuration/outbound-connections/application/OcApplicationSshView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/application/selenium",
    name: "oc-application-selenium",
    component: () =>
      import(
        /* webpackChunkName: "oc-application-selenium-view" */ "../views/configuration/outbound-connections/application/OcApplicationSeleniumView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/protocols/diameter",
    name: "oc-protocols-diameter",
    component: () =>
      import(
        /* webpackChunkName: "oc-protocols-diameter-view" */ "../views/configuration/outbound-connections/protocols/OcProtocolsDiameterView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/protocols/http",
    name: "oc-protocols-http",
    component: () =>
      import(
        /* webpackChunkName: "oc-protocols-http-view" */ "../views/configuration/outbound-connections/protocols/OcProtocolsHttpView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/protocols/ldap",
    name: "oc-protocols-ldap",
    component: () =>
      import(
        /* webpackChunkName: "oc-protocols-ldap-view" */ "../views/configuration/outbound-connections/protocols/OcProtocolsLdapView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/outbound-connections/protocols/radius",
    name: "oc-protocols-radius",
    component: () =>
      import(
        /* webpackChunkName: "oc-protocols-radius-view" */ "../views/configuration/outbound-connections/protocols/OcProtocolsRadiusView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  // Inbound connections
  {
    path: "/configuration/inbound-connections/2g-3g/aaa",
    name: "ic-2g-3g-aaa",
    component: () =>
      import(
        /* webpackChunkName: "ic-2g-3g-aaa-view" */ "../views/configuration/inbound-connections/2g-3g/Ic2g3gAaaView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/2g-3g/ggsn",
    name: "ic-2g-3g-ggsn",
    component: () =>
      import(
        /* webpackChunkName: "ic-2g-3g-ggsn-view" */ "../views/configuration/inbound-connections/2g-3g/Ic2g3gGgsnView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/2g-3g/ur",
    name: "ic-2g-3g-ur",
    component: () =>
      import(
        /* webpackChunkName: "ic-2g-3g-ur-view" */ "../views/configuration/inbound-connections/2g-3g/Ic2g3gUrView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/aaa",
    name: "ic-4g-aaa",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-aaa-view" */ "../views/configuration/inbound-connections/4g/Ic4gAaaView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/abmf",
    name: "ic-4g-abmf",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-abmf-view" */ "../views/configuration/inbound-connections/4g/Ic4gAbmfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/af",
    name: "ic-4g-af",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-af-view" */ "../views/configuration/inbound-connections/4g/Ic4gAfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/eir",
    name: "ic-4g-eir",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-eir-view" */ "../views/configuration/inbound-connections/4g/Ic4gEirView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/gmlc",
    name: "ic-4g-gmlc",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-gmlc-view" */ "../views/configuration/inbound-connections/4g/Ic4gGmlcView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/hss",
    name: "ic-4g-hss",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-hss-view" */ "../views/configuration/inbound-connections/4g/Ic4gHssView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/mme",
    name: "ic-4g-mme",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-mme-view" */ "../views/configuration/inbound-connections/4g/Ic4gMmeView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/ocf",
    name: "ic-4g-ocf",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-ocf-view" */ "../views/configuration/inbound-connections/4g/Ic4gOcfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/ofcs",
    name: "ic-4g-ofcs",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-ofcs-view" */ "../views/configuration/inbound-connections/4g/Ic4gOfcsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/pcrf",
    name: "ic-4g-pcrf",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-pcrf-view" */ "../views/configuration/inbound-connections/4g/Ic4gPcrfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/pgw",
    name: "ic-4g-pgw",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-pgw-view" */ "../views/configuration/inbound-connections/4g/Ic4gPgwView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/sgw",
    name: "ic-4g-sgw",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-sgw-view" */ "../views/configuration/inbound-connections/4g/Ic4gSgwView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/slf",
    name: "ic-4g-slf",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-slf-view" */ "../views/configuration/inbound-connections/4g/Ic4gSlfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/spr",
    name: "ic-4g-spr",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-spr-view" */ "../views/configuration/inbound-connections/4g/Ic4gSprView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/4g/epdg",
    name: "ic-4g-epdg",
    component: () =>
      import(
        /* webpackChunkName: "ic-4g-epdg-view" */ "../views/configuration/inbound-connections/4g/Ic4gEpdgView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/eir",
    name: "ic-5g-eir",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-eir-view" */ "../views/configuration/inbound-connections/5g/Ic5gEirView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/af",
    name: "ic-5g-af",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-af-view" */ "../views/configuration/inbound-connections/5g/Ic5gAfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/amf",
    name: "ic-5g-amf",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-amf-view" */ "../views/configuration/inbound-connections/5g/Ic5gAmfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/ausf",
    name: "ic-5g-ausf",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-ausf-view" */ "../views/configuration/inbound-connections/5g/Ic5gAusfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/bsf",
    name: "ic-5g-bsf",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-bsf-view" */ "../views/configuration/inbound-connections/5g/Ic5gBsfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/chf",
    name: "ic-5g-chf",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-chf-view" */ "../views/configuration/inbound-connections/5g/Ic5gChfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/dwdaf",
    name: "ic-5g-dwdaf",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-dwdaf-view" */ "../views/configuration/inbound-connections/5g/Ic5gDwdafView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/gmlc",
    name: "ic-5g-gmlc",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-gmlc-view" */ "../views/configuration/inbound-connections/5g/Ic5gGmlcView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/hss",
    name: "ic-5g-hss",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-hss-view" */ "../views/configuration/inbound-connections/5g/Ic5gHssView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/lmf",
    name: "ic-5g-lmf",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-lmf-view" */ "../views/configuration/inbound-connections/5g/Ic5gLmfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/nef",
    name: "ic-5g-nef",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-nef-view" */ "../views/configuration/inbound-connections/5g/Ic5gNefView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/nrf",
    name: "ic-5g-nrf",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-nrf-view" */ "../views/configuration/inbound-connections/5g/Ic5gNrfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/nssf",
    name: "ic-5g-nssf",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-nssf-view" */ "../views/configuration/inbound-connections/5g/Ic5gNssfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/otaf",
    name: "ic-5g-otaf",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-otaf-view" */ "../views/configuration/inbound-connections/5g/Ic5gOtafView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/pcf",
    name: "ic-5g-pcf",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-pcf-view" */ "../views/configuration/inbound-connections/5g/Ic5gPcfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/scef",
    name: "ic-5g-scef",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-scef-view" */ "../views/configuration/inbound-connections/5g/Ic5gScefView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/smf",
    name: "ic-5g-smf",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-smf-view" */ "../views/configuration/inbound-connections/5g/Ic5gSmfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/smsf",
    name: "ic-5g-smsf",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-smsf-view" */ "../views/configuration/inbound-connections/5g/Ic5gSmsfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/ucmf",
    name: "ic-5g-ucmf",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-ucmf-view" */ "../views/configuration/inbound-connections/5g/Ic5gUcmfView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/udm",
    name: "ic-5g-udm",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-udm-view" */ "../views/configuration/inbound-connections/5g/Ic5gUdmView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/udr",
    name: "ic-5g-udr",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-udr-view" */ "../views/configuration/inbound-connections/5g/Ic5gUdrView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/5g/vae",
    name: "ic-5g-vae",
    component: () =>
      import(
        /* webpackChunkName: "ic-5g-vae-view" */ "../views/configuration/inbound-connections/5g/Ic5gVaeView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/fixed-net/bras",
    name: "ic-fixed-net-bras",
    component: () =>
      import(
        /* webpackChunkName: "ic-fixed-net-bras-view" */ "../views/configuration/inbound-connections/fixed-net/IcFixedNetBrasView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/protocols/diameter",
    name: "ic-protocols-diameter",
    component: () =>
      import(
        /* webpackChunkName: "ic-protocols-diameter-view" */ "../views/configuration/inbound-connections/protocols/IcProtocolsDiameterView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/protocols/http",
    name: "ic-protocols-http",
    component: () =>
      import(
        /* webpackChunkName: "ic-protocols-http-view" */ "../views/configuration/inbound-connections/protocols/IcProtocolsHttpView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/protocols/ldap",
    name: "ic-protocols-ldap",
    component: () =>
      import(
        /* webpackChunkName: "ic-protocols-ldap-view" */ "../views/configuration/inbound-connections/protocols/IcProtocolsLdapView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/inbound-connections/protocols/radius",
    name: "ic-protocols-radius",
    component: () =>
      import(
        /* webpackChunkName: "ic-protocols-radius-view" */ "../views/configuration/inbound-connections/protocols/IcProtocolsRadiusView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  // Objects
  {
    path: "/configuration/objects/apn-b-numbers",
    name: "objects-apn-b-numbers",
    component: () =>
      import(
        /* webpackChunkName: "objects-apn-b-numbers-view" */ "../views/configuration/objects/objects/ApnBNumbersView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/objects/devices",
    name: "objects-devices",
    component: () =>
      import(
        /* webpackChunkName: "objects-devices-view" */ "../views/configuration/objects/objects/DevicesView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/objects/evaluation-parameters",
    name: "objects-evaluation-parameters",
    component: () =>
      import(
        /* webpackChunkName: "objects-evaluation-parameters-view" */ "../views/configuration/objects/objects/EvaluationParametersView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/objects/hss-objects",
    name: "objects-hss-objects",
    component: () =>
      import(
        /* webpackChunkName: "objects-hss-objects-view" */ "../views/configuration/objects/objects/HssObjectsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/objects/ldap-content",
    name: "objects-ldap-content",
    component: () =>
      import(
        /* webpackChunkName: "objects-ldap-content-view" */ "../views/configuration/objects/objects/LdapContentView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/objects/sgw",
    name: "objects-sgw",
    component: () =>
      import(
        /* webpackChunkName: "objects-sgw-view" */ "../views/configuration/objects/objects/SgwView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/objects/subscriber-mobile",
    name: "objects-subscriber-mobile",
    component: () =>
      import(
        /* webpackChunkName: "objects-subscriber-mobile-view" */ "../views/configuration/objects/objects/SubscriberMobileView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/objects/subscriber-sip",
    name: "objects-subscriber-sip",
    component: () =>
      import(
        /* webpackChunkName: "objects-subscriber-sip-view" */ "../views/configuration/objects/objects/SubscriberSipView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  // Dictionary
  {
    path: "/configuration/dictionary/radius-attributes",
    name: "dictionary-radius-attributes",
    component: () =>
      import(
        /* webpackChunkName: "dictionary-radius-attributes-view" */ "../views/configuration/objects/dictionary/RadiusAttributesView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/configuration/dictionary/diameter-attributes",
    name: "dictionary-diameter-attributes",
    component: () =>
      import(
        /* webpackChunkName: "dictionary-diameter-attributes-view" */ "../views/configuration/objects/dictionary/DiameterAttributesView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  // --------------
  // Administration
  // --------------
  // Users
  {
    path: "/administration/user/my-account",
    name: "my-account",
    component: () =>
      import(
        /* webpackChunkName: "my-account-view" */ "../views/administration/user/MyAccountView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/administration/user/my-projects",
    name: "my-projects",
    component: () =>
      import(
        /* webpackChunkName: "my-projects-view" */ "../views/administration/user/MyProjectsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  // Projects
  {
    path: "/administration/project/connectors",
    name: "connectors",
    component: () =>
      import(
        /* webpackChunkName: "connectors-view" */ "../views/administration/project/ConnectorsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/administration/project/api-documentation",
    name: "api-documentation",
    component: () =>
      import(
        /* webpackChunkName: "api-documentation-view" */ "../views/administration/project/ApiDocumentationView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/administration/project/spreadsheets",
    name: "spreadsheets",
    component: () =>
      import(
        /* webpackChunkName: "spreadsheets-view" */ "../views/administration/project/SpreadsheetsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  // Organization
  {
    path: "/administration/organization/general-settings",
    name: "general-organization-settings",
    component: () =>
      import(
        /* webpackChunkName: "general-organization-settings-view" */ "../views/administration/organization/GeneralOrganizationSettingsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/administration/organization/users",
    name: "users",
    component: () =>
      import(
        /* webpackChunkName: "users-view" */ "../views/administration/organization/UsersView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/administration/organization/project",
    name: "projects",
    component: () =>
      import(
        /* webpackChunkName: "projects-view" */ "../views/administration/organization/ProjectsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/administration/organization/permission-groups",
    name: "permission-groups",
    component: () =>
      import(
        /* webpackChunkName: "permission-groups-view" */ "../views/administration/organization/PermissionGroupsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/administration/organization/active-probes",
    name: "organization-active-probes",
    component: () =>
      import(
        /* webpackChunkName: "organization-active-probes-view" */ "../views/administration/organization/OrganizationActiveProbesView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/administration/organization/chart-templates",
    name: "chart-templates",
    component: () =>
      import(
        /* webpackChunkName: "chart-templates-view" */ "../views/administration/organization/ChartTemplatesView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/administration/organization/report-templates",
    name: "report-templates",
    component: () =>
      import(
        /* webpackChunkName: "report-templates-view" */ "../views/administration/organization/ReportTemplatesView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/administration/organization/explorers",
    name: "explorers",
    component: () =>
      import(
        /* webpackChunkName: "explorers-view" */ "../views/administration/organization/ExplorersView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/administration/organization/device-pools",
    name: "device-pools",
    component: () =>
      import(
        /* webpackChunkName: "device-pools-view" */ "../views/administration/organization/DevicePoolsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/administration/organization/sims",
    name: "sims",
    component: () =>
      import(
        /* webpackChunkName: "sims-view" */ "@/views/administration/organization/SimsView.vue"
      ),
    props: (route) => ({ ...getTableParams(route) }),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/administration/organization/sims/history/:id",
    name: "sim-history",
    component: () =>
      import(
        /* webpackChunkName: "sims-history-view" */ "@/views/administration/organization/SIMHistoryView.vue"
      ),
    props: true,
    meta: {
      isAuthRequired: true,
    },
  },

  // -----------
  // Super Admin
  // -----------
  // SIM Management
  {
    path: "/super-admin/sim-server",
    name: "sim-server",
    component: () =>
      import(
        /* webpackChunkName: "sim-server-view" */ "../views/super-admin/sim-management/SimServerView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/sim-slots",
    name: "sim-slots",
    component: () =>
      import(
        /* webpackChunkName: "sim-slots-view" */ "../views/super-admin/sim-management/SimSlotsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/sim-card-infos",
    name: "sim-card-infos",
    component: () =>
      import(
        /* webpackChunkName: "sim-card-infos-view" */ "../views/super-admin/sim-management/SimCardInfosView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/sim-to-project-assignment",
    name: "sim-to-project-assignment",
    component: () =>
      import(
        /* webpackChunkName: "sim-to-project-assignment-view" */ "../views/super-admin/sim-management/SimToProjectAssignmentView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  // Client Management
  {
    path: "/super-admin/client-locations",
    name: "client-locations",
    component: () =>
      import(
        /* webpackChunkName: "client-locations-view" */ "../views/super-admin/client-management/ClientLocationsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/client-connection-history",
    name: "client-connection-history",
    component: () =>
      import(
        /* webpackChunkName: "client-connection-history-view" */ "../views/super-admin/client-management/ClientConnectionHistoryView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/clients-performance",
    name: "clients-performance",
    component: () =>
      import(
        /* webpackChunkName: "clients-performance-view" */ "../views/super-admin/client-management/ClientsPerformanceView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/modems-usage",
    name: "modems-usage",
    component: () =>
      import(
        /* webpackChunkName: "modems-usage-view" */ "../views/super-admin/client-management/ModemsUsageView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/network-quality-measures",
    name: "network-quality-measures",
    component: () =>
      import(
        /* webpackChunkName: "network-quality-measures-view" */ "../views/super-admin/client-management/NetworkQualityMeasuresView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  // Super Admin
  {
    path: "/super-admin/active-probes",
    name: "super-admin-active-probes",
    component: () =>
      import(
        /* webpackChunkName: "super-admin-active-probes-view" */ "../views/super-admin/super-admin/SuperAdminActiveProbesView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/configure-emails",
    name: "configure-emails",
    component: () =>
      import(
        /* webpackChunkName: "configure-emails-view" */ "../views/super-admin/super-admin/ConfigureEmailsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/email-server-config",
    name: "email-server-config",
    component: () =>
      import(
        /* webpackChunkName: "email-server-config-view" */ "../views/super-admin/super-admin/EmailServerConfigView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/help-text",
    name: "help-text",
    component: () =>
      import(
        /* webpackChunkName: "help-text-view" */ "../views/super-admin/super-admin/HelpTextView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/log-view",
    name: "log-view",
    component: () =>
      import(
        /* webpackChunkName: "log-view-view" */ "../views/super-admin/super-admin/LogView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/testcase-search-details",
    name: "testcase-search-details",
    component: () =>
      import(
        /* webpackChunkName: "testcase-search-details-view" */ "../views/super-admin/super-admin/TestcaseSearchDetailsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/organization",
    name: "organization",
    component: () =>
      import(
        /* webpackChunkName: "organization-view" */ "../views/super-admin/super-admin/OrganizationView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/running-subscriber",
    name: "running-subscriber",
    component: () =>
      import(
        /* webpackChunkName: "running-subscriber-view" */ "../views/super-admin/super-admin/RunningSubscriberView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/switch-user",
    name: "switch-users",
    component: () =>
      import(
        /* webpackChunkName: "switch-users-view" */ "../views/super-admin/super-admin/SwitchUsersView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/super-admin-panel",
    name: "super-admin-panel",
    component: () =>
      import(
        /* webpackChunkName: "super-admin-panel-view" */ "../views/super-admin/super-admin/SuperAdminPanelView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/service-log",
    name: "service-log",
    component: () =>
      import(
        /* webpackChunkName: "service-log-view" */ "../views/super-admin/super-admin/ServiceLogView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/super-admin/all-running-tests",
    name: "all-running-tests",
    component: () =>
      import(
        /* webpackChunkName: "all-running-tests-view" */ "../views/super-admin/super-admin/AllRunningTestsView.vue"
      ),
    meta: {
      isAuthRequired: true,
    },
  },

  // --------------
  // Misc
  // --------------
  {
    path: "/welcome",
    name: "welcome",
    component: () => import(/* webpackChunkName: "welcome-view" */ "../views/misc/WelcomeView.vue"),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "/sandbox",
    name: "sandbox",
    component: () => import(/* webpackChunkName: "sandbox-view" */ "../views/misc/SandboxView.vue"),
    meta: {
      isAuthRequired: true,
    },
  },

  {
    path: "*",
    // for Vue 3 with Vue Router 4
    // path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import(/* webpackChunkName: "not-found-view" */ "../views/NotFoundView.vue"),
  },
];
