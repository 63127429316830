<template>
  <v-hover>
    <template #default="{ hover }">
      <div class="search-router-link" @click="$emit('click', $event)">
        <v-icon v-if="hover" class="search-for-name-lens" @click="$emit('input', text)">
          mdi-magnify
        </v-icon>

        <router-link :to="to">
          <slot>
            {{ text }}
          </slot>
        </router-link>
      </div>
    </template>
  </v-hover>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: "SearchRouterLink",
  props: {
    value: String,
    text: String,
    to: [String, Object],
  },
});
</script>

<style scoped>
.search-router-link {
  position: relative;
}

.v-icon.search-for-name-lens {
  position: absolute;
  left: -24px;
  cursor: pointer;
}
</style>
