<script>
export default {
  metaInfo: {
    title: "Forgot password",
  }
};
</script>

<script setup>
import PhpServiceView from '@/components/basic/php-wrappers/PhpServiceView.vue';
import ForgotPassword from '@/components/dynamic/forgotPassword.vue';
</script>

<template>
  <PhpServiceView url="?f=global&f2=resetPasswordRequest" public #default="{result}">
    <ForgotPassword :result="result"/>
  </PhpServiceView>
</template>


