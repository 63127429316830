var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panel',{attrs:{"hover":"","tile":"","focusable":"","multiple":"","dense":""}},[_c('v-expansion-panel-header',{staticClass:"sortableHeader accordionHeader stepHeadColor",attrs:{"id":_vm.stepType  + '_' + _vm.stepID + '_stepHeader'}},[_c('stepHeader',{attrs:{"result":_vm.result,"stepType":_vm.stepType,"stepID":_vm.stepID,"header":_vm.header,"additionalStepsInfo":_vm.additionalStepsInfo,"stepStoredValue":_vm.stepStoredValue,"ipcanStepTemplates":_vm.ipcanStepTemplates,"groupedStepDependentInfo":_vm.groupedStepDependentInfo,"templateValue":_vm.templateValue,"localData":_vm.localData,"forWhat":_vm.forWhat,"groupValue":_vm.groupValue},on:{"click":function($event){return _vm.checkClicked(...arguments)}}})],1),_c('v-expansion-panel-content',{staticClass:"step testLevel application",attrs:{"id":_vm.stepID + '_' + _vm.stepType + '_stepContainer',"data-is-rendered":"false","data-stepid":_vm.stepID,"data-step-identifier":_vm.stepIdentifier,"eager":_vm.stepType === 'use_stencil'}},[_c('div',[_c('i',{staticClass:"step-help fas fa-question-circle fs16 stepHelp",attrs:{"data-test-step":_vm.stepValue,"data-help-link":"stepHelpFlag"}})]),_c('div',{staticClass:"mt-3 requestTable"},[_c('conditionContainer',{attrs:{"result":_vm.result},model:{value:(_vm.condition),callback:function ($$v) {_vm.condition=$$v},expression:"condition"}},[_c('groupCombobox',{attrs:{"options":_vm.groupedType,"value":_vm.stepTypeSelectorValue,"result":_vm.result,"id":'stepSelector_' + _vm.stepID,"stepType":_vm.stepType,"label":'Step Type'},on:{"input":function($event){return _vm.updateTypeValue(...arguments)}}})],1),_c('div',{staticClass:"mt-5"},[(typeof _vm.templateName != 'undefined' && _vm.templateName != null)?_c('dynamicComponent',{attrs:{"type":_vm.templateName,"result":_vm.result,"additional":{
              stepStoredValue: _vm.stepStoredValue,
              stepType: _vm.stepType,
              groupedStepDependentInfo: _vm.groupedStepDependentInfo,
              additionalStepsInfo: _vm.additionalStepsInfo,
              stepID: _vm.stepID,
              ipcanStepTemplates: _vm.ipcanStepTemplates,
              isValid: _vm.isValid,
            }},on:{"stored-value-changed":function($event){return _vm.processStepChanged(...arguments)},"update-additional-steps-info":_vm.updateAdditionalStepsInfo,"local-data-changed":_vm.updateLocalData,"revalidate":function($event){return _vm.$emit('revalidate')},"changeIsValid":function($event){return _vm.$emit('changeIsValid', $event)},"update:header":function($event){_vm.header = $event},"copy-stencil-steps":function($event){return _vm.copyStencilSteps(...arguments)}}}):_vm._e()],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }