<script>
export default {
  metaInfo: {
    title: "My Account",
  }
};
</script>

<script setup>
import MyAccount from '@/components/dynamic/myAccount.vue';
import PhpServiceView from '@/components/basic/php-wrappers/PhpServiceView.vue';
</script>

<template>
  <PhpServiceView url="?f=administration&f2=userSelfService&f3=myAccount" #default="{result}">
    <MyAccount :result="result"/>
  </PhpServiceView>
</template>


