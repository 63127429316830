<template>
  <div style="display: contents;">
    <XDataTable
      class="running-tests-view"
      title="Running Tests"
      show-total
      :headers="headers"
      :items-request="getRunningTestStatus"
      :items-request-params="itemsRequestParams"
      :refresh="10"
      :row-actions="rowActions"
      :select-actions="selectActions"
      @sorting-change="(header) => updateSortingData(header.value)"
      no-count
    >
      <template #search-input-slot>
        <XTextField
          class="running-tests-view__search-text-field"
          :value="search"
          clearable
          label="Search"
          append-icon="mdi-magnify"
          @input="(searchStr) => handleSearchUpdate(searchStr)"
        />
      </template>

      <template #[`item.testInfo`]="{row}">
        <TestInfoButton
          class="running-tests-view__test-info-button"
          :test-id="row.id"
          :test-id-key="shaKey(row.id)"
        />
      </template>

      <template #[`item.testCase.name`]="{ value, row: item }">
        <ClickableText2
          :text="value"
          :to="getTestQueueRoute(item.id)"
        />
      </template>

      <template #[`item.subscriber`]="{value,row}">
        <SubscriberExplorerLabel
            :type="row.type"
            :value="value"
        />
      </template>

      <template #[`item.owner`]="{ value }">
        <v-tooltip top>
          <template #activator="{ attrs, on }">
            <span
              class="running-tests-view__owner-first-name"
              v-bind="attrs"
              v-on="on"
            >
              {{ value.firstName }}
            </span>
          </template>

          <span>
            {{ `${value.firstName} ${value.lastName}` }}
          </span>
        </v-tooltip>
      </template>

      <template #[`item.status`]="{value, row}">
        <div>
          <v-icon :color="testStatus.find(x => x.id === value).color">
            {{ testStatus.find(x => x.id === value).icon }}
          </v-icon>

          <span>{{ row.currentStep.id }}</span>

          <span v-if="row.currentStep.name">
            <template v-if="shortProtocolsMapping[row.currentStep.name]">
              ({{ shortProtocolsMapping[row.currentStep.name] }})
            </template>

            <template v-else>
              ({{ row.currentStep.name }})
            </template>
          </span>
        </div>
      </template>

      <template #pagination-slot="{ metadata }">
        <PaginationBlock
          :total-count="metadata.totalCount || 0"
          :current-page="page"
          :items-per-page="metadata.perPage || 0"
          @update:current-page="(pageN) => setPageN(pageN)"
        />
      </template>

      <template #items-per-page-slot>
        <XSelect
          class="running-test-view__items-per-page-select"
          :items="ITEMS_PER_PAGE_OPTIONS_DEFAULT"
          :value="itemsPerPage"
          @input="(_itemsPerPage) => updateItemsPerPage(_itemsPerPage)"
          label="Items per page"
          required
          :autocomplete="false"
        />
      </template>
    </XDataTable>
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide } from "vue"
import { useRoute } from "vue-router/composables"
import XDataTable from '@/components/basic/XDataTable.vue';
import PaginationBlock from "@/components/basic/tables/PaginationBlock.vue";
import XTextField from '@/components/basic/XTextField.vue';
import ClickableText2 from '@/components/basic/ClickableText2.vue';
import XSelect from '@/components/basic/XSelect.vue';
import TestInfoButton from '@/components/specific/TestInfo/TestInfoButton.vue';
import SubscriberExplorerLabel from '@/components/specific/SubscriberExplorerLabel.vue';
import testCaseTypes from '@/cfg/testCaseTypes.json';
import testStatus from '@/cfg/testStatus.json';
import shortProtocolsMapping from '@/cfg/shortProtocolsMapping.json';
import { shaKey } from "@/js/helper";
import cockpitTestStatusService from '@/js/services/CockpitTestStatusService';
import testCaseInfoService from '@/js/services/TestCaseInfoService';
import { secondsToDuration2, unixToDateTimeString } from '@/js/general';
import { useSearchInputWithRouter, MIN_SEARCH_LENGTH } from "@/composition/filtering-components/use-search-input-w-router"
import { useTableSettingsWithRouter, ITEMS_PER_PAGE_OPTIONS_DEFAULT } from "@/composition/tables/use-table-settings"
import { usePaginationWithRouter } from "@/composition/filtering-components/use-pagination-w-router"
import { useTableSortingWithRouter } from "@/composition/filtering-components/use-sorting-w-router"
import { useTimeRangeSelect } from "@/composition/filtering-components/use-time-range-select"
import { debounce } from "lodash-es"

// Termporary solution
// TODO: remove this function after using new table components
function getRunningTestStatus(
  search,
  itemsPerPage,
  sortBy,
  descending,
  rangeFrom,
  rangeTo,
  page,
  params,
  then,
  error
) {
  params["search"] = search
  params["items-per-page"] = itemsPerPage
  params["sortBy"] = sortBy
  params["descending"] = descending
  params["from"] = rangeFrom
  params["to"] = rangeTo
  params["page"] = page
  cockpitTestStatusService.getRunningTestStatus(params, then, error)
}

const TABLE_SETTINGS_KEY = "running-tests-view"

export default defineComponent({
  name: 'RunningTestsView',

  components: {
    SubscriberExplorerLabel,
    TestInfoButton,
    ClickableText2,
    XDataTable,
    XTextField,
    XSelect,
    PaginationBlock,
  },

  setup() {
    const route = useRoute()
    const {
      search,
      searchAsReqParam,
    } = useSearchInputWithRouter()


    const {
      page,
      setPageN
    } = usePaginationWithRouter()

    const {
      settings: tableSettings,
      updateItemsPerPage: updItmsPerPage,
    } = useTableSettingsWithRouter({ key: TABLE_SETTINGS_KEY, defaultSettings: { itemsPerPage: 25 } })
    const itemsPerPageFromUrl = ref(route.query.itemsPerPage ? parseInt(`${route.query.itemsPerPage}`) : null)
    /**
     * @param {number} numberOfItems
     */
    const updateItemsPerPage = (numberOfItems) => {
      updItmsPerPage(numberOfItems)
      if (itemsPerPageFromUrl.value) {
        itemsPerPageFromUrl.value = null
      }
    }
    const itemsPerPage = computed(() => {
      if (itemsPerPageFromUrl.value) {
        return itemsPerPageFromUrl.value
      }
      return tableSettings.value.itemsPerPage
    })

    const {
      sortingData,
      updateSortingData,
    } = useTableSortingWithRouter()

    const {
      range,
      rangeAsReqParams,
    } = useTimeRangeSelect()

    provide("x-data-table-refactoring", {
      sortingData,
    })

    const stoppedTestIds = ref([])

    return {
      search,
      searchAsReqParam,
      itemsPerPage,
      updateItemsPerPage,
      sortingData,
      updateSortingData,
      range,
      rangeAsReqParams,
      page,
      setPageN,

      getRunningTestStatus,
      ITEMS_PER_PAGE_OPTIONS_DEFAULT,

      stoppedTestIds,
    }
  },

  data() {
    return {
      testCaseTypes: testCaseTypes,
      testStatus: testStatus,
      shortProtocolsMapping: shortProtocolsMapping,
      shaKey: shaKey,
      headers: Object.freeze([
        {
          value: 'testInfo',
          width: 48,
        },
        {
          text: 'Test Name',
          value: 'testCase.name',
          sortable: true,
        },
        {
          text: 'Type',
          value: 'type',
          formatter: value => this.testCaseTypes[value],
          width: 120,
          sortable: true,
        },
        {
          text: 'Subscriber/Explorer',
          value: 'subscriber',
          width: 200,
          sortable: true,
        },
        {
          text: 'Owner',
          value: 'owner',
          width: 120,
        },
        {
          text: 'Start Time',
          value: 'startTime',
          formatter: value => unixToDateTimeString(value),
          width: 142,
          sortable: true,
        },
        {
          text: 'Triggered Time',
          value: 'triggeredTime',
          formatter: value => value ? unixToDateTimeString(value) : '-',
          width: 161,
          sortable: true,
        },
        {
          text: 'Duration',
          value: 'duration',
          formatter: value => secondsToDuration2(value, { useColonFormat: true }),
          width: 120,
          sortable: true,
        },
        {
          text: 'Status',
          value: 'status',
          width: 220,
          sortable: true,
        },
        {
          text: 'Audit',
          value: 'audit',
        }
      ]),
      rowActions: [{
        icon: 'mdi-stop',
        color: 'red',
        click: (item) => {
          this.stoppedTestIds.push(item.id);
          testCaseInfoService.stopTests([item.id], () => {
            this.showNotification(`Stopping test ${item.testGroupId}-${item.id} ${item.testCase.name}.`, 3000);
          });
        },
        disabled: (item) => {
          return this.stoppedTestIds.includes(item.id);
        }
      }],
      selectActions: Object.freeze([
        {
          icon: 'mdi-stop',
          iconColor: 'red',
          text: 'Stop Selected',
          click: (selectedItems) => {
            this.stoppedTestIds.push(...selectedItems);
            testCaseInfoService.stopTests(selectedItems);
            this.showNotification(`Stopping ${selectedItems.length} tests.`, 3000);
          },
        },
      ]),
    };
  },

  computed: {
    itemsRequestParams() {
      return [
        this.searchAsReqParam,
        this.itemsPerPage,
        this.sortingData.sortBy,
        this.sortingData.descending,
        this.rangeAsReqParams.from,
        this.rangeAsReqParams.to,
        this.page,
      ];
    },
  },

  methods: {
    getTestQueueRoute(id) {
      const idKey = shaKey(id.toString());
      return { name: "test-info", params: { id, idKey } };
    },

    handleSearchUpdate: debounce(async function(searchStr) {
        this.search = searchStr;
        if ((searchStr.length && searchStr.length >= MIN_SEARCH_LENGTH) || this.search.length < MIN_SEARCH_LENGTH && !searchStr.length) {
          this.setPageN(1);
        }
    }, 500),
  },
});
</script>

<style lang="scss">
.running-tests-view {
  &__owner-first-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__search-text-field {
    flex: 1 1 auto;
    max-width: 350px;
  }

  &__test-info-button {
    margin-top: 9px;
    padding-left: 8px;
  }

  &__items-per-page-select {
    width: 120px;
    flex-shrink: 0;
  }
}
</style>

