<script setup>
import { defineProps, onUnmounted, ref } from "vue";
import VIntersectionObserver from "@/components/basic/web-api/VIntersectionObserver.vue"
import { debounce } from "lodash-es";

const props = defineProps({
  dataFetcher: {
    type: Function,
    required: true,
  },
  refreshSec: {
    type: Number,
    default: 0,
    validator: (value) => value >= 0
  },
  debounceMs: {
    type: Number,
    default: 0,
    validator: (value) => value >= 0
  }
});

const data = ref(null);
const error = ref(null);
const loading = ref(false);
const timeoutId = ref(null);

const fetchData = async () => {
  if (loading.value) return;

  try {
    loading.value = true;
    error.value = null;
    data.value = await props.dataFetcher();
  } catch (fetchError) {
    error.value = fetchError;
    console.error('Data fetching failed:', fetchError);
  } finally {
    loading.value = false;
  }
};

const debouncedFetcher = props.debounceMs > 0
  ? debounce(fetchData, props.debounceMs)
  : fetchData;

const removeTimeout = () => {
  if (timeoutId.value) {
    clearTimeout(timeoutId.value);
    timeoutId.value = null;
  }
};

const startPeriodicRefresh = () => {
  removeTimeout();

  if (props.refreshSec > 0) {
    timeoutId.value = setTimeout(() => {
      fetchData();
      startPeriodicRefresh();
    }, props.refreshSec * 1000);
  }
};

const whenVisible = async () => {
  if (!data.value) {
    await debouncedFetcher();
  }

  startPeriodicRefresh();
};

const onNotVisible = () => {
  if (typeof debouncedFetcher.cancel === 'function') {
    debouncedFetcher.cancel();
  }
  removeTimeout();
};

onUnmounted(() => {
  removeTimeout();
  if (typeof debouncedFetcher.cancel === 'function') {
    debouncedFetcher.cancel();
  }
});
</script>

<template>
  <div class="lazy-loading-skeleton">
    <VIntersectionObserver
      @visible="whenVisible"
      @not-visible="onNotVisible"
    />

    <div
      v-if="!data"
      class="lazy-loading-skeleton__box"
    ></div>

    <div v-else-if="error">
      <slot name="error" :error="error">
        An error occurred while loading data.
      </slot>
    </div>

    <slot v-else :data="data" />
  </div>
</template>

<style lang="scss">

.lazy-loading-skeleton {
  height: 100%;
  width: 100%;

  &__box {
    height: 100%;
    @include dark-inline-skeleton();
  }
}

</style>

