import { initTokenRefresher } from "@acctopus-fe/auth-service"
import Cookies from "js-cookie";
import { HTTPStatusCodes } from "@acctopus-fe/http-client"
import { ref } from "vue"

const TOKEN_KEYS = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
}

const tokenStorageManager = {
  get(name) {
    return localStorage.getItem(name);
  },

  set(name, token) {
    localStorage.setItem(name, token);
  },

  remove(name) {
    localStorage.removeItem(name);
  }
};

const _tokenRefresher = initTokenRefresher({
  url: `${process.env.VUE_APP_AUTH_SERVICE}/auth-service/v1/refresh`,
  refreshTokenKey: TOKEN_KEYS.REFRESH_TOKEN,
  jwtTokenKey: TOKEN_KEYS.ACCESS_TOKEN,
  cookieManager: tokenStorageManager,
  createBodyRequest(refresh_token, access_token) {
    return JSON.stringify({
      refresh_token,
      access_token,
    })
  },
});

/**
 * Cockpit Vue 2 requires a bit different approach to handle token refreshing.
 * We need to proxy the token refresher and store the token lifetime in the local storage.
 */
export class TokenLifetimeKeeper {
  static TOKEN_LIFETIME = 1000 * 60 * 60 // 1 hour

  static MINIMAL_DIFF = 1000 * 60 // 1 minute

  static LIFE_TIME_KEY = "token-lifetime"

  static getTokenLifetime = () => new Date().getTime() + TokenLifetimeKeeper.TOKEN_LIFETIME

  static doesItNeedRefresh() {
    const tokenLifetime = localStorage.getItem(TokenLifetimeKeeper.LIFE_TIME_KEY)
    if (!tokenLifetime) {
      return true
    }
    const now = new Date().getTime()
    return now > parseInt(tokenLifetime, 10) + TokenLifetimeKeeper.MINIMAL_DIFF
  }

  static storeLifetime() {
    const tokenLifetime = TokenLifetimeKeeper.getTokenLifetime().toString()
    localStorage.setItem(TokenLifetimeKeeper.LIFE_TIME_KEY, tokenLifetime)
  }

  static removeLifetime() {
    localStorage.removeItem(TokenLifetimeKeeper.LIFE_TIME_KEY)
  }
}

class TokenRefresherProxy {
  static METHODS_TO_PROXY = ["login", "logout", "refresh"]

  constructor(tokenRefresher) {
    this.tokenRefresher = tokenRefresher

    return new Proxy(this.tokenRefresher, {
      get: (target, prop, receiver) => {
        if (TokenRefresherProxy.METHODS_TO_PROXY.includes(prop)) {
          return async (...args) => {
            return this[`_${prop}`](...args)
          }
        }

        return Reflect.get(target, prop, receiver)
      },
    })
  }

  _login(pair) {
    this.tokenRefresher.login(pair)
    TokenLifetimeKeeper.storeLifetime()
  }

  _logout() {
    this.tokenRefresher.logout()
    TokenLifetimeKeeper.removeLifetime()
  }

  async _refresh(attempt = 0) {
    let isRefreshed = false

    if (!this.tokenRefresher.isAuthenticated) {
      // run subscribers
      this.tokenRefresher.logout()
      return isRefreshed
    }

    try {
      isRefreshed = await this.tokenRefresher.refresh(attempt)
    } catch (e) {
      if (e?.response?.status === HTTPStatusCodes.FORBIDDEN) {
        this.tokenRefresher.logout()
      } else {
        throw e
      }
    }

    if (isRefreshed) {
      TokenLifetimeKeeper.storeLifetime()
    } else {
      TokenLifetimeKeeper.removeLifetime()
    }

    return isRefreshed
  }
}

export const tokenRefresher = new TokenRefresherProxy(_tokenRefresher)

export function useReactiveAuthFlag() {
  const isAuthenticated = ref(tokenRefresher.isAuthenticated)

  const sub = ({ isAuth }) => {
    isAuthenticated.value = isAuth
  }

  tokenRefresher.subscribe("post-login", sub)
  tokenRefresher.subscribe("post-logout", sub)

  return {
    isAuthenticated,
  }
}

export const getAccessToken = () => tokenStorageManager.get(TOKEN_KEYS.ACCESS_TOKEN);

export const relogin = async (tokens) => {
  TokenLifetimeKeeper.removeLifetime()
  await tokenRefresher.login(tokens)
}

export const migrateCookieToStorage = () => {
  const new_access_token = tokenStorageManager.get(TOKEN_KEYS.ACCESS_TOKEN); 
  // we already migrated
  if (new_access_token?.length) {
    return;
  }

  const access_token = Cookies.get(TOKEN_KEYS.ACCESS_TOKEN);
  const refresh_token = Cookies.get(TOKEN_KEYS.REFRESH_TOKEN);
  if (!access_token || !refresh_token) {
    return;
  }
  tokenStorageManager.set(TOKEN_KEYS.ACCESS_TOKEN, access_token);
  tokenStorageManager.set(TOKEN_KEYS.REFRESH_TOKEN, refresh_token);
  Cookies.remove(TOKEN_KEYS.ACCESS_TOKEN);
  Cookies.remove(TOKEN_KEYS.REFRESH_TOKEN);
};

