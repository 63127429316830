<script>
export default {
  metaInfo: {
    title: "Activate",
  },
};
</script>

<script setup>
import PhpServiceView from "@/components/basic/php-wrappers/PhpServiceView.vue";
import {getURLSearchParam} from "@/js/general";
import {useRouter} from "vue-router/composables";

const router = useRouter();

const code = getURLSearchParam('code');
const email = getURLSearchParam('email');

const url = `?f=global&f2=activate&code=${code}&email=${email}`;

const redirectToLogin = () => {
  router.push({name: 'login'});
};
</script>

<template>
  <PhpServiceView :url="url" @result="redirectToLogin" public/>
</template>


