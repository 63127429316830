<template>
  <router-link
    v-if="!computedTooltip"
    :class="{
      'clickable-txt-2__icon-link': true,
      'clickable-txt-2__icon-link--disabled': computedDisabled,
    }"
    :disabled="computedDisabled"
    :event="computedDisabled ? '' : 'click'"
    :to="to"
  >
    <v-icon
      v-if="computedIcon"
      :disabled="computedDisabled"
      :color="computedIconColor"
    >
      {{ computedIcon }}
    </v-icon>

    <span v-if="computedText">
      {{ computedText }}
    </span>
  </router-link>

  <v-tooltip v-else top>
    <template #activator="{ on, attrs }">
      <router-link
        :to="to"
        :class="{
          'clickable-txt-2__icon-link': true,
          'clickable-txt-2__icon-link--disabled': computedDisabled,
        }"
        :disabled="computedDisabled"
        :event="computedDisabled ? '' : 'click'"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          v-if="computedIcon"
          :disabled="computedDisabled"
          :color="computedIconColor"
        >
          {{ computedIcon }}
        </v-icon>

        <span v-if="computedText">
          {{ computedText }}
        </span>
      </router-link>
    </template>

    {{ computedTooltip }}
  </v-tooltip>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: "ClickableText",

  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    value: Object,
    text: [String, Number],
    icon: String,
    iconColor: String,
    tooltip: String,
    disabled: Boolean,
  },

  computed: {
    computedText() {
      if (this.text) return this.text;
      if (this.value) return this.value.text;
      return undefined;
    },

    computedIcon() {
      if (this.icon) return this.icon;
      if (this.value) return this.value.icon;
      return undefined;
    },

    computedIconColor() {
      if (this.iconColor) return this.iconColor;
      if (this.value) return this.value.iconColor;
      return undefined;
    },

    computedTooltip() {
      if (this.tooltip) return this.tooltip;
      if (this.value) return this.value.tooltip;
      return undefined;
    },

    computedDisabled() {
      if (this.disabled) return this.disabled;
      if (this.value) return this.value.disabled;
      return undefined;
    },
  },
});
</script>

<style lang="scss">
.clickable-txt-2 {
  &__icon-link {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    color: var(--v-anchor-base);

    &#{&}--disabled {
      cursor: default;
      color: var(--v-disabled-base);
    }
  }
}
</style>
