<template>
  <div>
    <RemoteTooltip
      :path="`serve.php${testInfoLink}`"
      :additionalAttributes="additionalAttributes"
      :dialogAttributes="{}"
    >
      <router-link :to="testLink">
        <v-icon>mdi-information</v-icon>
      </router-link>
    </RemoteTooltip>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import RemoteTooltip from "@/commonComponents/remoteTooltip.vue";

export default defineComponent({
  name: "TestInfoButton",
  components: {
    RemoteTooltip
  },
  props: {
    testId: Number,
    testIdKey: String,
  },
  setup(props) {
    const testInfoLink = "?f=testing&f2=testInfo&function=getInfo&n_id=";

    const testLink = computed(() => ({ name: "test-info", params: { id: props.testId, idKey: props.testIdKey } }));

    const additionalAttributes = computed(() => ({
        function: "getInfoMini",
        n_id: props.testId,
        n_id_key: props.testIdKey,
    }));

    return {
      testInfoLink,
      testLink,
      additionalAttributes,
    }
  },
});
</script>

<style scoped>

</style>
