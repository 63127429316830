<script>
import { defineComponent, inject } from "vue";
import MenuLinkList from "@/components/specific/menu/MenuLinkList.vue";
import { useAdministrationMenu } from "@/composition/menu/use-administration-menu";

export default defineComponent({
  name: "AdministrationMenu",

  components: {
    MenuLinkList
  },

  setup() {
    const { usersLinks, projectsLinks, organizationLinks } = useAdministrationMenu();
    const { closeMenu } = inject("MENU_BTN");

    return {
      usersLinks,
      projectsLinks,
      organizationLinks,
      closeMenu,
    };
  },
});
</script>

<template>
  <div class="administration-menu">
    <div class="administration-menu__list-box">
      <MenuLinkList
        title="User"
        :links="usersLinks"
        @navigation-click="() => closeMenu()"
      />

      <v-divider
        v-if="projectsLinks.length && organizationLinks.length"
        class="administration-menu__divider"
      />

      <MenuLinkList
        v-if="projectsLinks.length"
        title="Project"
        :links="projectsLinks"
        @navigation-click="() => closeMenu()"
      />
    </div>

    <div class="administration-menu__list-box">
      <MenuLinkList
        title="Organization"
        :links="organizationLinks"
        @navigation-click="() => closeMenu()"
      />
    </div>
  </div>
</template>

<style lang="scss">
.administration-menu {
  display: flex;
  width: 350px;

  &__list-box {
    flex-basis: 50%;
  }

  &__divider {
    width: 90%;
    margin: 15px 0 25px 0;
  }
}
</style>
