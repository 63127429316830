import { ref, onScopeDispose } from "vue"
import { useRouter } from "vue-router/composables"
import { simService } from "@/api"
import { CUSTOM_FIELDS_KEY } from "./use-sim-configuration"
import { useSimsStore } from "@/composition/sim/use-sims-store"

const ACTIONS_COL = { name: "", key: "actions", sortable: false }
export const COLUMNS = [
  // "Name" is always first and is always present
  { name: "Name", key: "name", sortable: true },
  { name: "Description", key: "description", sortable: true },
  { name: "ICCID", key: "iccid", sortable: true },
  { name: "Status", key: "status", sortable: true },
  { name: "Tags", key: "tags", sortable: false },
  { name: "Owner", key: "user", sortable: false },
  { name: "Operator", key: "operator", sortable: true },
  { name: "MSISDN", key: "msisdn", sortable: true },
  { name: "IMSI", key: "imsi", sortable: true },
  { name: "Location", key: "location", sortable: true },
  { name: "SMSC", key: "smsc", sortable: true },
  { name: "APN", key: "apn", sortable: true },
  // Does it exist in the API?
  // { name: "Occupancy", key: "occupancy_graph", sortable: false },
]
const COLUMNS_MAP = new Map(COLUMNS.map((c) => [c.key, c]))
const NAME_COL_KEY = COLUMNS[0].key

export const generateCustomFieldKey = (key) =>
  key.startsWith(`${CUSTOM_FIELDS_KEY}.`) ? key : `${CUSTOM_FIELDS_KEY}.${key}`

export const getCustomFieldId = (key) => key.split(".").pop()

const CustomFieldColumn = ({ key, name }) => {
  const _k = generateCustomFieldKey(key)
  return { key: _k, name, sortable: false }
}

export const useSimTable = ({
  params = {
    page: 1,
    itemsPerPage: 25,
    search: "",
    sortBy: "",
    descending: false,
    historyFor: 0,
    full: false,
    tagQuery: "",
  },
  refreshMs = 30000,
} = {}) => {
  const { fetchOrgTagsForSims, fetchOrgCustomFields, fetchWalletsList, orgCustomFieldsMap, lastTagQuery } =
    useSimsStore()

  const router = useRouter()
  const isLoading = ref(false)
  const simsList = ref([])
  const displayableColumns = ref([])
  const columnsConfigList = ref([])
  // eslint-disable-next-line no-undef
  const _params = ref(structuredClone(params))
  const _cache = new Map()
  const _generateCacheKey = (_p) => JSON.stringify(_p)

  const meta = ref({
    page: 1,
    pageCount: 1,
    itemsPerPage: 25,
    totalCount: 0,
    columns: [],
  })

  const updateRouteQuery = (newParams) => {
    router.push({
      query: {
        ...router.currentRoute.query,
        page: newParams.page,
        itemsPerPage: newParams.itemsPerPage,
        search: newParams.search,
        sortBy: newParams.sortBy,
        descending: newParams.descending,
        full: newParams.full,
        tagQuery: newParams.tagQuery,
      },
    })
  }

  if (!_params.value.tagQuery.length && lastTagQuery.value.length) {
    _params.value.tagQuery = lastTagQuery.value
    updateRouteQuery(_params.value)
  }

  const updateMeta = (newMeta) => {
    meta.value = newMeta
  }

  const retrieveMeta = (resp) => {
    const { page, pageCount, perPage: itemsPerPage, totalCount, columns } = resp._metadata

    return {
      page,
      pageCount,
      itemsPerPage,
      totalCount,
      columns,
    }
  }

  const updateParams = async (newParams) => {
    _params.value = newParams
    updateRouteQuery(newParams)
    await updateSimList(_params.value)
  }

  const createDisplayableColumns = (visibleCols) => {
    const _cols = [COLUMNS[0]] // "Name" column is always first

    for (const _k of visibleCols) {
      if (_k === NAME_COL_KEY) continue // Skip "name" column

      if (COLUMNS_MAP.has(_k)) {
        _cols.push(COLUMNS_MAP.get(_k))
      } else {
        const id = getCustomFieldId(_k)
        const field = orgCustomFieldsMap.value.get(id)

        if (!field) {
          console.warn(`Custom field with id "${id}" not found among org custom fields. Skipping.`)
          continue
        }

        _cols.push(CustomFieldColumn({ key: id, name: field.name }))
      }
    }

    displayableColumns.value = [ACTIONS_COL, ..._cols]
  }

  const updateSimList = async (__params, { triggerLoading = true } = {}) => {
    const cacheKey = _generateCacheKey(__params)
    const cachedData = _cache.get(cacheKey)
    const now = Date.now()

    if (cachedData && now - cachedData.timestamp < refreshMs) {
      // Serve cached data
      simsList.value = cachedData.records
      updateMeta(retrieveMeta(cachedData.response))
    } else {
      // Fetch new data and revalidate
      if (triggerLoading) {
        isLoading.value = true
      }
      try {
        const [response] = await Promise.all([
          simService.v2.getSimsTable(__params),
          fetchOrgCustomFields({ isRefresh: true }),
        ])
        simsList.value = response.records || []

        if (simsList.value.length > 0) {
          updateMeta(retrieveMeta(response))
          _cache.set(cacheKey, { records: response.records, response, timestamp: now })
        }

        // do not need to wait for the response
        fetchOrgTagsForSims({ isRefresh: true })
        fetchWalletsList({ isRefresh: true });
      } catch (err) {
        simsList.value = []
        throw err
      } finally {
        isLoading.value = false
      }
    }
  }

  const createColumnsConfigList = (_enabledColumns) => {
    const enabledKeys = new Set(_enabledColumns.map((c) => c.key))
    const customFieldSet = new Set(
      Array.from(orgCustomFieldsMap.value.keys()).map(generateCustomFieldKey)
    )

    const _list = [
      ..._enabledColumns.map((c) => ({
        id: c.key,
        name: c.name,
        enabled: true,
        frozen: c.key === NAME_COL_KEY,
      })),
      ...COLUMNS.filter((c) => !enabledKeys.has(c.key)).map((c) => ({
        id: c.key,
        name: c.name,
        enabled: false,
        frozen: false,
      })),
      ...[...customFieldSet]
        .filter((cf) => !enabledKeys.has(cf))
        .map((cf) => ({
          id: cf,
          name: orgCustomFieldsMap.value.get(getCustomFieldId(cf))?.name,
          enabled: false,
          frozen: false,
        })),
    ]

    columnsConfigList.value = _list
  }

  const updateColumns = (newColumnsConfigList) => {
    columnsConfigList.value = newColumnsConfigList

    const enabledColumnsKeys = newColumnsConfigList
      .filter((columnConfig) => columnConfig.enabled)
      .map((columnConfig) => {
        const defaultCol = COLUMNS_MAP.get(columnConfig.id)
        return defaultCol ? defaultCol.key : getCustomFieldId(columnConfig.id)
      })

    // can be without await, we don't need to wait for the response
    simService.v2.updateVisibleColumns(enabledColumnsKeys)
    createDisplayableColumns(enabledColumnsKeys)
  }

  const actualizeSimList = (newSim) => {
    const idx = simsList.value.findIndex((s) => s.id === newSim.id)
    if (idx !== -1) {
      simsList.value.splice(idx, 1, newSim)
    } else {
      simsList.value.push(newSim);
    }
  }

  const filterDisplayableFields = (fieldsToDelete) => {
    displayableColumns.value = displayableColumns.value.filter(
      (col) => !fieldsToDelete.includes(getCustomFieldId(col.key))
    )
  }

  const updateCustomFieldName = (key, newName) => {
    const colIndex = displayableColumns.value.findIndex((col) => getCustomFieldId(col.key) === key)
    if (colIndex >= 0) {
      displayableColumns.value[colIndex].name = newName
    }
    const configIndex = columnsConfigList.value.findIndex((col) => getCustomFieldId(col.id) === key)
    if (configIndex >= 0) {
      columnsConfigList.value[configIndex].name = newName
    }
  }

  let intervalId = null
  onScopeDispose(() => {
    clearInterval(intervalId)
  })
  const init = async () => {
    await updateSimList(_params.value)

    createDisplayableColumns(meta.value.columns)
    const enabledColumns = displayableColumns.value.slice(1) // Skip "actions" column
    createColumnsConfigList(enabledColumns)

    // Start periodic refresh
    intervalId = setInterval(() => {
      updateSimList(_params.value, { triggerLoading: false })
    }, refreshMs)
  }

  return {
    simsList,
    init,
    displayableColumns,
    isLoading,
    updateParams,
    params: _params,
    meta,
    columnsConfigList,
    updateCustomFieldName,
    updateRouteQuery,
    updateColumns,
    createColumnsConfigList,
    filterDisplayableFields,
    actualizeSimList,
    createDisplayableColumns,
  }
}
