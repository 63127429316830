<template>
  <div class="table-view-table">
    <v-data-table
        v-model="selected"
        :search="search"
        :headers="headers"
        :items="data"
        :show-select="showSelect"
        class="datatable"
        :id="id"
        :fixed-header="fixedHeader"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
        :single-select="false"
        loading-text
        :no-data-text="noRecordInfo"
        :item-key="currentTable.tableItemKey"
        :sort-by="sortBy"
        @update:sort-by="updateSortBy"
        :sort-desc="sortDesc"
        @update:sort-desc="updateSortByDesc"
        dense
        :disable-pagination="typeof hidePaging != 'undefined' && hidePaging === true ? true : false"
        :custom-filter="tableSearch"
        @current-items="currentItems"
        :ref="id"
        selectable-key="selectable"
        :item-class="getRowClasses"
        :custom-sort="customSort"
    >
      <template
          v-if="currentTable.tableEmptyMessage"
          v-slot:no-data>{{ currentTable.tableEmptyMessage }}
      </template>
      <template
          v-slot:header=""
          v-if="typeof currentTable.multiLevelHeader != 'undefined'"
      >
        <thead>
        <tr
            v-for="(row, i) in currentTable['multiLevelHeader']"
            :key="i"
            v-bind="currentTable['multiLevelHeaderAttrs']"
        >
          <td
              v-for="(arrayProperties, title) in row"
              :colspan="arrayProperties[0]"
              :rowspan="arrayProperties[1]"
              :key="title"
              v-bind="getTableAttrs(arrayProperties)"
          >
              <span v-if="typeof arrayProperties[2] != 'undefined'">
                {{ arrayProperties[2].name }}
              </span>
          </td>
        </tr>
        </thead>
      </template>
      <template v-slot:[`item.data-table-select`]="{ isSelected, select,item }">
        <div style="display: flex;">
          <testIcons :type="item.c_type"/>
          <v-simple-checkbox
              v-bind="currentTable.selectProps"
              v-if="item['selectable'] === true"
              :value="isSelected"
              @input="select($event)"
          ></v-simple-checkbox>
        </div>
      </template>
      <template v-slot:[`header.data-table-select`]></template>
      <template
          v-for="(h, index) in headers"
          v-slot:[`item.${h.value}`]="{ isMobile, item, value }"
      >
        <firstLine
            v-if="h.value == 'firstLine'"
            :key="index"
            :isMobile="isMobile"
            :row="item"
            :header="h.value"
            :currentTable="currentTable"
            :result="result"
            v-on="$listeners"
            :rowindex="0"
            :value="value"
        ></firstLine>
        <tableRowFieldValue
            v-else
            :key="index"
            :isMobile="isMobile"
            :row="item"
            :header="h.value"
            :currentTable="currentTable"
            :result="result"
            v-on="$listeners"
            :rowindex="0"
            :value="value"
            @item-clicked="emitItem(0,item,...arguments)"
        ></tableRowFieldValue>
      </template>
      <template v-slot:footer>
        <template v-if="currentTable.hideFooter != true">
          <v-divider class="pt-1"></v-divider>
          <div class="pl-7">
            <div class="table-view-table__bottom-box">
                <tableBottomControls
                    :table="currentTable"
                    @all-selected="toggleSelectAll(...arguments)"
                    :allSelected="allSelected"
                    :selectedRows="selectedRows"
                    @delete-selected="deleteSelected()"
                    :result="result"
                ></tableBottomControls>
                <template>
                <deleteRow
                    v-for="(line, id) in deletedObject"
                    :key="id"
                    :row="line"
                    :info="{}"
                    :currentTable="currentTable"
                    :confirmation="confirmationObject[id]"
                    @confirmation-ok="$set(confirmationObject, id, false);
                                                          $delete(deletedObject, id);
                                                          $delete(selectedRows, id);"
                ></deleteRow>
              </template>
              <v-col class="d-flex flex-row-reverse"
                     v-if="!currentTable.disablePagging"
              >
                <div class="mt-3 mr-2 text-center d-flex">
                  <v-pagination
                      v-model="page"
                      :length="pageCount"
                      :total-visible="pageVisible"
                  ></v-pagination>
                  <div id="itemsPerPagesContainer">
                    <v-select
                        style="width: 110px"
                        :value="itemsPerPage"
                        label="Items per page"
                        type="number"
                        min="-1"
                        max="15"
                        @input="itemsPerPage = parseInt($event, 10)"
                        outlined
                        dense
                        :items="customPages"
                        id="itemsPerPages"
                    ></v-select>
                  </div>
                </div>
              </v-col>
            </div>
          </div>
        </template>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import deleteRow from "@/commonComponents/deleteRow.vue";
import tableBottomControls from "@/commonComponents/tableBottomControls.vue";
import firstLine from "@/commonComponents/firstLine.vue";
import tableRowFieldValue from "@/commonComponents/tableRowFieldValue.vue";
import testIcons from "@/commonComponents/testIcons.vue";

export default {
  components: {
    //tableViewBody,
    tableBottomControls,
    deleteRow,
    tableRowFieldValue,
    firstLine,
    testIcons
  },

  props: {
    currentTable: {type: Object},
    search: String,
    result: Object,
    hidePaging: Boolean,
  },

  data: function () {
    return {
      selectedRows: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 25,
      //pages:  [10, 25, 50, 100],
      //pages: [10, 25, 50, 100, { text: "All", value: -1 }],
      allSelected: false,
      deletedObject: {},
      pageVisible: 7,
      confirmationObject: {},
    };
  },
  created() {
    this.$root.$refs[this.id] = this;
  },
  computed: {
    customPages() {
      let pages = [10, 25, 50, 100];
      if (this.$route.query.f2 == 'testsGroup') {
        pages = [10, 25, 50, 100, {text: "All", value: -1}];
      }
      return pages;
    },
    noRecordInfo() { //future may need to check data for the message based on type/ at the moment not possible
      let explorerMsg = "If your test is supposed to run on Explorers, the set up filter may currently not match any active Explorers.";
      let message = "No Result Found!";
      if (typeof this.$route.query.type != 'undefined' && this.$route.query.type == '2') {
        message = message + " " + explorerMsg;
      }
      return message;
    },
    fixedHeader() {
      let fixedHeader = true;
      if (typeof this.currentTable.fixedHeader != "undefined") {
        fixedHeader = this.currentTable.fixedHeader
      }
      return fixedHeader;
    },
    selected: {
      get() {
        return Object.values(this.selectedRows)
      }, set(newSelected) {
        let selectedRows = {};
        newSelected.forEach(line => {
          selectedRows[line[this.result.indexRow]] = line;
        });
        if (newSelected.length == this.$refs[this.id].$children[0].filteredItems.length) {
          this.allSelected = true
        } else {
          this.allSelected = false
        }
        this.selectedRows = selectedRows
      }
    },
    sortBy: {
      get() {
        let sortBy = [];
        if (typeof this.currentTable.sortBy != "undefined") {
          sortBy = this.currentTable.sortBy;
        } else {
          for (let [key, info] of Object.entries(this.currentTable.header)) {
            if (
                typeof info.tableAttr != "undefined" &&
                typeof info.tableAttr.class != "undefined" &&
                info.tableAttr.class.indexOf("defaultSort") > -1
            ) {
              // remove hidden
              sortBy = key;
              break;
            }
          }
        }
        return sortBy;
      },
      set(newVal) {
        console.log(newVal);
      },
    },
    sortDesc() {
      let sortDesc = [];
      if (typeof this.currentTable.sortDesc != "undefined") {
        sortDesc = this.currentTable.sortDesc;
      } else {
        for (let info of Object.values(this.currentTable.header)) {
          if (
              typeof info.tableAttr != "undefined" &&
              typeof info.tableAttr.class != "undefined" &&
              info.tableAttr.class.indexOf("descendSort") > -1
          ) {
            // remove hidden
            sortDesc = true;
            break;
          }
        }
      }
      return sortDesc;
    },
    showSelect() {
      let showSelect = true;
      if (this.currentTable["firstLine"] === "false") {
        showSelect = false;
      }
      return showSelect;
    },
    id: function () {
      if (this.isset(this.currentTable["id"])) {
        return this.currentTable["id"];
      } else {
        return "dataTable";
      }
    },
    headers: function () {
      let headers = [];
      if (typeof this.currentTable != "undefined") {
        if (this.currentTable["firstLine"] === "true") {
          let attrs = {};
          if (
              this.currentTable.firstDataLineAttributes
          ) {
            attrs = this.currentTable.firstDataLineAttributes;
          }
          let line = {
            value: "firstLine",
            text: "",
            class: "subtitle-1  font-weight-bold ",
            sortable: false,
            align: "start",
          };
          line = {...line, ...attrs};
          headers.push(line);

        }

        for (let [key, info] of Object.entries(this.currentTable.header)) {
          if (
              !(
                  typeof info.tableAttr != "undefined" &&
                  typeof info.tableAttr.style != "undefined" &&
                  info.tableAttr.style.indexOf("display:none;") > -1
              ) &&
              typeof info.historyInfo == "undefined" &&
              (typeof info.tableAttr == "undefined" ||
                  typeof info.tableAttr.show == "undefined" ||
                  (this.$vuetify.breakpoint[info.tableAttr.show] &&
                      this.$vuetify.breakpoint.smAndUp))
          ) {
            // remove hidden
            let classes = "";
            if (
                typeof info.tableAttr != "undefined" &&
                typeof info.tableAttr.class != "undefined"
            ) {
              classes = info.tableAttr.class;
            }

            let sortable = true;
            if (
                typeof info.sortable != "undefined") {
              sortable = info.sortable;
            }

            let line = {
              ...info.tableAttr,
              value: key,
              text: info.name,
              class: "subtitle-1  font-weight-bold " + classes,
              sortable: sortable
            };
            if (this.$vuetify.breakpoint.width < 1400) {
              line = {
                value: key,
                text: info.name,
                class: "subtitle-1  font-weight-bold " + classes,
                sortable: sortable
              };
            }
            headers.push(line);
          }
        }
      }
      return headers;
    },
    data: function () {
      let data;
      if (this.currentTable.filteredData) {
        data = this.currentTable.filteredData;
      } else {
        data = this.currentTable.data;
      }
      return data;
    },
  },
  watch: {
    search() {
      this.selectedRows = {};
      this.allSelected = false;
    },
    "currentTable.id": function (id) {
      this.page = 1;
      this.itemsPerPage = 25;
      this.$root.$refs[id] = this; // for count row
      this.selectedRows = {};
    },
    itemsPerPage: function () {
      this.page = 1;
    },
    "currentTable.data": function (newVal, oldVal) {
      this.currentItems();
      if (newVal.length != oldVal.length) {
        this.page = 1;
      }
    },
  },
  methods: {
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (typeof a[index] == 'string' && typeof b[index] == 'string' && a[index] != null && b[index] != null) {
          let first = a[index];
          let second = b[index];
          if (this.currentTable.header[index].mask == "date" && first != 'not set' && second != 'not set') {
            first = a[index + "unix"];
            second = b[index + "unix"];
          }
          if (isNaN(first) || isNaN(second)) {
            if (!isDesc[0]) {
              return first.toLowerCase().localeCompare(second.toLowerCase());
            } else {
              return second.toLowerCase().localeCompare(first.toLowerCase());
            }
          } else {
            first = parseInt(first);
            second = parseInt(second);
            if (!isDesc[0]) {
              return this.compareInt(first, second);
            } else {
              return this.compareInt(second, first);
            }
          }
        } else if (typeof a[index] == 'number' && typeof b[index] == 'number') {
          let first = a[index];
          let second = b[index];
          if (!isDesc[0]) {
            return this.compareInt(first, second);
          } else {
            return this.compareInt(second, first);
          }
        } else { //null in table
          if (a[index] === b[index]) { // equal items sort equally
            return 0;
          } else if (a[index] === null) { // nulls sort after anything else
            return 1;
          } else if (b[index] === null) {
            return -1;
          } else if (!isDesc[0]) { // otherwise, if we're ascending, lowest sorts first
            return a[index] < b[index] ? -1 : 1;
          } else { // if descending, highest sorts first
            return a[index] < b[index] ? 1 : -1;
          }
        }
      });
      return items;
    }
    ,
    compareInt(a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      // a must be equal to b
      return 0;
    },
    emitItem(rowindex, row, actionName) {
      this.$emit("row-item-clicked", rowindex, row, actionName);
    },
    getRowClasses(item) {
      let attrs = {...item.singleRowAttr, ...this.currentTable.rowAttr};
      if (typeof item.singleRowAttr != "undefined") {
        attrs.class = attrs.class + " " + item.singleRowAttr.class
      }
      return attrs.class
    },
    /*  getFiltered(item){ // after Search // or any filter result count!
    //  console.log(item.itemsLength);
      this.$set(this.currentTable, "dataCount", item.itemsLength);
    },*/
    currentItems() {
      let baseTable = this.id;
      if (
          typeof this.$root.$refs[baseTable].$children[0].$children[0]
              .filteredItems != "undefined"
      ) {
        let listedItems =
            this.$root.$refs[baseTable].$children[0].$children[0].filteredItems;
        let itemsLength =
            this.$root.$refs[baseTable].$children[0].$children[0].itemsLength;
        // console.log(this.$root.$refs.baseTable.$children[0].$children[0]);
        // console.log(itemsLength);
        this.$set(this.currentTable, "currentItems", listedItems);
        this.$set(this.currentTable, "dataCount", itemsLength);
      }
    },
    updateSortByDesc(value) {
      if (typeof value == "undefined") {
        value = false;
      }
      this.$set(this.currentTable, "sortDesc", value);
      this.$store.commit("tableChanged", this.currentTable);
    },
    updateSortBy(value) {
      let lastValue = this.currentTable.sortBy;
      if (value != "") {
        if (typeof value == "undefined") {
          value = "";
        }
        this.$set(this.currentTable, "sortBy", value);
        this.$store.commit("tableChanged", this.currentTable);
        if (typeof value == "undefined" || value == "") {
          this.$nextTick(function () {
            this.$set(this.currentTable, "sortBy", lastValue);
            this.$store.commit("tableChanged", this.currentTable);
          });
        }
      }
    },
    getTableAttrs(arrayProperties) {
      let tableAttr = {};
      if (
          typeof arrayProperties[2] != "undefined" &&
          typeof arrayProperties[2].tableAttr != "undefined"
      ) {
        tableAttr = arrayProperties[2].tableAttr;
      }
      return tableAttr;
    },
    buildSearch(value) {
      let returnedValue = "";
      if (typeof value == "object" && value != null) {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            returnedValue += " " + this.buildSearch(v);
          });
          //value = value.join(" ");
        } else {
          Object.values(value).forEach((v) => {
            returnedValue += " " + this.buildSearch(v);
          });
          // value = Object.values(value).join(" ");
        }
      } else {
        returnedValue = value;
      }
      return returnedValue;
    },
    tableSearch(value, search) {
      value = this.buildSearch(value);
      return (
          value != null &&
          search != null &&
          typeof value === "string" &&
          value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },
    deleteSelected() {
      // console.log(this.selectedRows);
      this.deletedObject = {...this.selectedRows};
    },
    toggleSelectAll(val) {
      this.allSelected = val;
      let selected = {};
      if (val) {
        this.$refs[this.id].$children[0].filteredItems.forEach((element) => {
          if (element.selectable) {
            selected[element[this.result.indexRow]] = element;
          }
        });
      }
      this.$set(this, "selectedRows", selected);
    },
  },
};
</script>

<style lang="scss">
.table-view-table {
  &__bottom-box {
    display: flex;
    justify-content: space-between;
  }
}
</style>

<style scoped>
/deep/ .v-pagination__item {
  width: unset !important;
}
</style>
