<script>
export default {
  metaInfo: {
    title: "Welcome",
  }
};
</script>

<script setup>
import PhpServiceView from "@/components/basic/php-wrappers/PhpServiceView.vue";
import WelcomePageContent from "@/components/dynamic/welcomePageContent.vue";
</script>

<template>
  <PhpServiceView url="?f=administration&f2=info" #default="{result}">
    <WelcomePageContent :result="result"/>
  </PhpServiceView>
</template>

