<template>
  <div class="tdHeightStabilizer" :id="ocsTableInfo.ocsTableId + '_container'">
    <v-card class="mb-6 mt-3 ml-1" elevation="0" outlined>
      <v-toolbar color="primary" dark flat height="50">
        <v-toolbar-title class="subtitle-2 d-flex">
          <span class="mr-2">{{ pageTitle }}</span>
          <addField
            v-if="result.tablesInfo.addable === 'true'"
            :path="this.ocsTableInfo.tableUrl"
            :additionalAttributes="{}"
          >
            <v-icon class="mt-n1" :id="ocsTableInfo.ocsTableId + '_add_button'"
              >mdi-plus-box</v-icon
            >
          </addField>
        </v-toolbar-title>
      </v-toolbar>
      <v-container>
        <ocsMainTable
          :ocsTableInfo="ocsTableInfo"
          :result="result"
          :search="search"
          :filter="filter"
          v-if="typeof ocsTableInfo['tablesData'] != 'undefined'"
        ></ocsMainTable>
        <ocsMainTableBody
          v-else-if="typeof ocsTableInfo['data'] != 'undefined'"
          :ocsTableInfo="ocsTableInfo"
          :result="result"
          :search="search"
          v-on="$listeners"
        ></ocsMainTableBody>

        <div v-else>{{ languagePack("info_NoRecords") }}</div>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import addField from "@/commonComponents/addField.vue";
import ocsMainTableBody from "@/components/legacy/ocsMainTableBody.vue";
import ocsMainTable from "@/components/legacy/ocsMainTable.vue";
export default {
  components: { ocsMainTable, addField, ocsMainTableBody },

  props: ["ocsTableInfo", "result", "search", "filter"],
  methods: {
    addOcsFields() {
      /*{if count(result.ocsTypes) eq 1}onclick="getProtocolObjectNOCS('{$p}','{$type}','{result.ocsTypes[0]}',this);" {else} onclick="AddField('','','{ocsTableInfo.tablesURL}&p={urlencode($p)}&c_subType={$type}',{},this,{});" {/if} */
    },
  },
  computed: {
      pageTitle(){
          let title = this.ocsTableInfo.pageTitle;
          const path = this.$route.path;

          if (path.includes("/5g/")) {
              if (title === "Local Clients") {
                  title = " " + this.languagePack("5gTitle", "localClient");
              } else if (title === "Remote Services") {
                  title = " " + this.languagePack("5gTitle", "remoteServices");
              } else if (title === "Remote Clients") {
                  title = " " + this.languagePack("5gTitle", "remoteClients");
              } else if (title === "Local Services") {
                  title = " " + this.languagePack("5gTitle", "localServices");
              }
          }
          return title;
      },
  },
};
</script>
