<script>
export default {
  metaInfo: {
    title: "Reset password",
  }
};
</script>

<script setup>
import PhpServiceView from "@/components/basic/php-wrappers/PhpServiceView.vue";
import ResetPasswordNoticeContent from "@/components/dynamic/resetPasswordNotice.vue";
</script>

<template>
  <PhpServiceView url="?f=global&f2=resetPasswordRequest&function=statusInfo" #default="{result}" public>
    <ResetPasswordNoticeContent :result="result"/>
  </PhpServiceView>
</template>


