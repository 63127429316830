<script>
export default {
  metaInfo: {
    title: "Active Probes",
  }
};
</script>

<script setup>
import PhpServiceTableView from '@/components/basic/php-wrappers/PhpServiceTableView.vue';
</script>

<template>
  <PhpServiceTableView url="?f=administration&f2=activeProbes"/>
</template>

