export const SCHEDULED_START_TYPE = 1;
export const MANUAL_START_TYPE = 2;
export const API_START_TYPE = 3;

export const GROUP_START_TYPES = {
  [SCHEDULED_START_TYPE]: "Scheduled",
  [MANUAL_START_TYPE]: "Manual",
  [API_START_TYPE]: "API",
};

